import React, { useState } from 'react';
import Chatbot from './Chatbot/Chatbot';
import { useAuth } from '../context/AuthContext';
import { FaTimes } from 'react-icons/fa';
import { deleteChatbotData } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const ThemeCustomizer = ({ chatbotData, setChatbotData, fetchChatbotData }) => {
  const [themeColor, setThemeColor] = useState(chatbotData.themeColor || '#3B82F6');
  const [backgroundType, setBackgroundType] = useState('color');
  const [themeType, setThemeType] = useState(chatbotData.themeType || 'light');
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [avatar, setAvatar] = useState(chatbotData.avatar || '');
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [customPersonaDescription, setCustomPersonaDescription] = useState('');
  const [activeAvatarTab, setActiveAvatarTab] = useState('upload');
  const [isGeneratingAvatar, setIsGeneratingAvatar] = useState(false);
  const [name, setName] = useState(chatbotData.name || '');
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // New state for modal
  const { isAuthenticated, getAccessToken } = useAuth();

  const navigate = useNavigate();

  const gradients = [
    { name: 'Blue to Purple', value: 'linear-gradient(to right, #3B82F6, #9333EA)' },
    { name: 'Sunset', value: 'linear-gradient(to right, #FF7E5F, #FEB47B)' },
    { name: 'Cool Blue', value: 'linear-gradient(to right, #6A11CB, #2575FC)' },
    { name: 'Green Beach', value: 'linear-gradient(to right, #02AAB0, #00CDAC)' },
    { name: 'Orange Coral', value: 'linear-gradient(to right, #FF9966, #FF5E62)' },
    { name: 'Purple Bliss', value: 'linear-gradient(to right, #360033, #0b8793)' },
  ];

  const updateChatbotData = async (chatbot_id, data) => {
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${chatbot_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update chatbot. Status: ${response.status}. Error: ${errorData.detail}`);
      }

      setSuccessMessage('Theme has been saved successfully!');
    } catch (error) {
      console.error('Error updating chatbot:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBackgroundTypeChange = (value) => {
    setBackgroundType(value);
    if (value === 'gradient') {
      const newTheme = gradients[0].value;
      setThemeColor(newTheme);
      handleThemeChange(newTheme);
    } else {
      handleThemeChange(themeColor);
    }
  };

  const handleThemeChange = (newTheme) => {
    setThemeColor(newTheme);
    setChatbotData({
      ...chatbotData,
      themeColor: newTheme,
      themeType: themeType,
    });
  };

  const handleSaveTheme = async () => {
    setIsSaving(true);
    setSuccessMessage('');
    await updateChatbotData(chatbotData.chatbot_id, { name, themeColor, themeType, avatar });
    fetchChatbotData();
  };

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append('bucket_name', 'smarterchatbots');
    formData.append('file', file);
    formData.append('file_dir', 'avatars');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/s3/upload_file_to_s3`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload file');
      }
  
      const data = await response.json();
      return data.full_url; // Assuming the response contains the location of the uploaded file
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  
  const handleAvatarChange = async (file) => {
    if (file) {
      try {
        const fileLocation = await uploadFileToS3(file);
        setAvatar(fileLocation);
        setChatbotData({
          ...chatbotData,
          avatar: fileLocation,
        });
      } catch (error) {
        console.error('Error handling avatar change:', error);
      }
    }
  };

  const handleAvatarUrlChange = (e) => {
    const url = e.target.value;
    setAvatarUrl(url);
    setAvatar(url);
    setChatbotData({
      ...chatbotData,
      avatar: url,
    });
  };

  const handleGenerateAvatar = async () => {
    setIsGeneratingAvatar(true);
    setIsSaving(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/generate-avatar/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text_prompt: customPersonaDescription }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setAvatarUrl(data.url);
      setAvatar(data.url);
      setChatbotData({
        ...chatbotData,
        avatar: data.url,
      });
    } catch (error) {
      console.error('Error generating avatar:', error);
    } finally {
      setIsGeneratingAvatar(false);
      setIsSaving(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleAvatarChange(file);
  };

  const handleDelete = async () => {
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        await deleteChatbotData(accessToken, chatbotData.chatbot_id);
        setDeleteMessage('Chatbot deleted successfully!');
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error deleting chatbot:', error);
      setDeleteMessage('Failed to delete chatbot.');
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="flex flex-col md:flex-row p-4 bg-gray-800 rounded-lg space-y-4 md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <h2 className="text-xl font-bold mb-4 text-white">Customize Theme</h2>

        {/* Name Input */}
        <div className="mb-6">
          <label className="block text-white font-semibold mb-2">Chatbot Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full text-white bg-gray-800 border border-gray-600 rounded-md px-2 py-1"
            placeholder="Enter chatbot name"
          />
        </div>

        {/* Avatar Selection */}
        <div className="mb-6">
          <label className="block text-white font-semibold mb-2">Avatar</label>
          <div className=" items-center">
            <img src={avatar} alt="Avatar" className="w-24 h-24 rounded-full object-cover border-2 border-white mb-2" />
            <button
              onClick={() => setIsEditingAvatar(true)}
              className="bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-1 px-3 rounded-full hover:opacity-90"
            >
              Change Avatar
            </button>
          </div>
          {isEditingAvatar && (
            <div className="mt-4 p-4 bg-gray-700 rounded-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-white">Edit Avatar</h3>
                <button onClick={() => setIsEditingAvatar(false)} className="text-gray-400 hover:text-white">
                  <FaTimes size={20} />
                </button>
              </div>
              {/* Tabs */}
              <div className="mb-4">
                <nav className="flex space-x-2">
                  <button
                    onClick={() => setActiveAvatarTab('upload')}
                    className={`py-1 px-2 rounded-t-lg ${
                      activeAvatarTab === 'upload' ? 'bg-gray-800 text-white' : 'bg-gray-600 text-gray-300'
                    }`}
                  >
                    Upload File
                  </button>
                  <button
                    onClick={() => setActiveAvatarTab('generator')}
                    className={`py-2 px-4 rounded-t-lg ${
                      activeAvatarTab === 'generator' ? 'bg-gray-800 text-white' : 'bg-gray-600 text-gray-300'
                    }`}
                  >
                    Generator
                  </button>
                  <button
                    onClick={() => setActiveAvatarTab('url')}
                    className={`py-2 px-4 rounded-t-lg ${
                      activeAvatarTab === 'url' ? 'bg-gray-800 text-white' : 'bg-gray-600 text-gray-300'
                    }`}
                  >
                    Image URL
                  </button>
                </nav>
              </div>
              {/* Tab Content */}
              <div className="p-4 bg-gray-800 rounded-b-lg">
                {activeAvatarTab === 'upload' && (
                  <div
                    className="text-center"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <label
                      htmlFor="avatar-upload"
                      className="flex flex-col items-center justify-center h-32 border-2 border-dashed border-gray-600 rounded-lg cursor-pointer hover:border-gray-500"
                    >
                      <svg
                        className="w-12 h-12 text-gray-500 mb-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1M16 11l-4-4m0 0l-4 4m4-4v12" />
                      </svg>
                      <span className="text-gray-400">Drag & Drop or Click to Upload</span>
                    </label>
                    <input
                      id="avatar-upload"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleAvatarChange(e.target.files[0])}
                      className="hidden"
                    />
                  </div>
                )}
                {activeAvatarTab === 'generator' && (
                  <div>
                    {!isGeneratingAvatar ? (
                      <>
                        <label className="block text-white mb-2">Describe Your Avatar:</label>
                        <div className="space-x-2">
                          <textarea
                            placeholder="Enter description"
                            value={customPersonaDescription}
                            onChange={(e) => setCustomPersonaDescription(e.target.value)}
                            className="text-white bg-gray-800 border border-gray-600 rounded-md w-full px-2 py-1"
                            rows="4"
                            cols="50"
                          />
                          <button
                            onClick={handleGenerateAvatar}
                            className="bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-1 px-3 rounded-full hover:opacity-90"
                          >
                            Generate
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center justify-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                      </div>
                    )}
                  </div>
                )}
                {activeAvatarTab === 'url' && (
                  <div>
                    <label className="block text-white mb-2">Image URL:</label>
                    <input
                      type="text"
                      placeholder="Enter Image URL"
                      value={avatarUrl}
                      onChange={handleAvatarUrlChange}
                      className="w-full text-white bg-gray-800 border border-gray-600 rounded-md px-2 py-1"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Background Type Selection */}
        <div className="mb-2">
          <label className="block text-white mb-2">Theme:</label>
          <div className="flex space-x-2">
            <button
              onClick={() => handleBackgroundTypeChange('color')}
              className={`flex-1 py-2 rounded-full ${
                backgroundType === 'color'
                  ? 'bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white'
                  : 'bg-gray-700 text-gray-300'
              } font-semibold text-sm hover:opacity-90`}
            >
              Solid Color
            </button>
            <button
              onClick={() => handleBackgroundTypeChange('gradient')}
              className={`flex-1 py-2 rounded-full ${
                backgroundType === 'gradient'
                  ? 'bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white'
                  : 'bg-gray-700 text-gray-300'
              } font-semibold text-sm hover:opacity-90`}
            >
              Gradient
            </button>
          </div>
        </div>

        {/* Color or Gradient Selection */}
        <div className="mb-6">
          {backgroundType === 'color' ? (
            <div>
              <input
                type="color"
                value={themeColor}
                onChange={(e) => {
                  const newColor = e.target.value;
                  setThemeColor(newColor);
                  handleThemeChange(newColor);
                }}
                className="w-full h-12 rounded-md cursor-pointer border-none"
                style={{ backgroundColor: themeColor }}
              />
            </div>
          ) : (
            <div>
              <label className="block text-white mb-2">Choose Gradient:</label>
              <div className="grid grid-cols-3 gap-2">
                {gradients.map((gradient, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      const newGradient = gradient.value;
                      setThemeColor(newGradient);
                      handleThemeChange(newGradient);
                    }}
                    className="w-full h-12 rounded-md border-2 border-transparent hover:border-blue-500"
                    style={{ background: gradient.value }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Save Theme Button */}
        <button
          onClick={handleSaveTheme}
          className="w-full bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-2 px-4 rounded-full hover:opacity-90"
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save Theme'}
        </button>

        {/* Success Message */}
        {successMessage && (
          <div className="mt-4 text-green-500">
            {successMessage}
          </div>
        )}
      </div>

      <div className="flex-shrink-0 md:w-1/2 w-full">
        
        <div className="w-full md:w-[300px] h-[400px] bg-transparent rounded-lg shadow-lg overflow-hidden mx-auto relative">
          
          <Chatbot 
          chatbotId={chatbotData.chatbot_id} 
          isClosed={false}
          tempColor={themeColor}
          tempAvatar={avatar}
          tempName={name}
          />
          
        </div>
       
       <div className="flex justify-center mt-4 w-full">
        <button type="button" onClick={handleDelete} className="text-white border rounded-pill px-2 py-1 text-sm">
          Delete Assistant
        </button>
        </div>

        {/* Delete Message */}
        {deleteMessage && (
          <div className="mt-4 text-red-500">
            {deleteMessage}
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-20">
          <div className=" p-6 rounded-lg shadow-lg bg-gray-900">
            <h2 className="text-lg font-semibold mb-4">Confirm Deletion</h2>
            <p className="mb-4">Are you sure you want to delete this assistant?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-pill text-sm"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded-pill text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemeCustomizer;
import React from 'react';

const FeaturesSection = () => {
  return (
    <section className="bg-gray-900 text-white py-12">
        <h2 className="text-3xl font-bold text-center mb-8">Key Features</h2>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        
        {/* Real-Time Automated Responses */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">24/7 Real-Time Automated Responses</h3>
          <p className="text-gray-400 mb-4">
            The generative AI instantly crafts accurate, context-aware responses, reducing response times and enhancing customer satisfaction by handling high inquiry volumes.
          </p>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/rounded-lg.gif" 
            alt="Real-Time Responses" 
            className="w-full object-contain  rounded-md"
            style={{ maxHeight: '400px' }}
          />
        </div>

        {/* AI-Driven Customer Insights */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">AI-Driven Customer Insights</h3>
          <p className="text-gray-400 mb-4">
            Gain deep insights into customer behavior through AI analysis of conversations, helping you optimize offerings and engagement strategies.
          </p>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Screenshot%202024-10-15%20at%206.01.31%20PM.png" 
            alt="AI-Driven Customer Insights" 
            className="w-full object-contain  rounded-md"
            style={{ maxHeight: '400px' }}
          />
        </div>


        {/* Multilingual Capabilities with AI */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Multilingual Capabilities with AI</h3>
          <p className="text-gray-400 mb-4">
            Communicate with a global audience through AI’s language models, seamlessly supporting multiple languages for broader reach.
          </p>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/spanish-resturant.gif?t=2024-10-16T01%3A31%3A38.913Z" 
            alt="Multilingual Capabilities" 
            className="w-full object-contain  rounded-md"
            style={{ maxHeight: '400px' }}
          />
        </div>

             {/* Seamless Scalability */}
             <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Seamless Scalability</h3>
          <p className="text-gray-400 mb-4">
            As customer demand grows, the chatbot effortlessly scales with your business, maintaining high-quality responses without additional staffing.
          </p>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/tothemoon.webp" 
            alt="Seamless Scalability" 
            className="w-full object-contain rounded-md"
            style={{ maxHeight: '400px' }}
          />
        </div>

        {/* Quick and Easy AI-Powered Setup */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Quick and Easy AI-Powered Setup</h3>
          <p className="text-gray-400 mb-4">
            Set up a fully functional chatbot in minutes with intuitive AI configuration, allowing fast deployment and minimal technical effort.
          </p>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Interior%20Design%20Brand%20Narrative%20and%20Promotion%20%20Business%20Instagram%20Reel%20in%20Yellow%20Green%20Black%20Elegant%20Serif%20Style.gif" 
            alt="Quick AI Setup" 
            className="w-full object-cover rounded-md overflow-hidden"
            style={{ maxHeight: '400px',  objectPosition: 'top'}}

          />
        </div>

               {/* Deploy Anywhere with Ease */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">Deploy Anywhere </h3>
          <p className="text-gray-400 mb-4">
            Our AI-powered chatbot can be deployed across various platforms effortlessly, including QR codes, websites, REST APIs, code snippets, SMS, and more. This ensures you can deploy your chatbot anywhere your customers our at.
          </p>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/Integrations.png" 
            alt="Deploy Anywhere with Ease" 
            className="w-full object-contain rounded-md"
            style={{ maxHeight: '400px' }}
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
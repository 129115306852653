import React, { useState } from 'react';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import { createChatbot } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const GuidedChatbotSetupUI = ({ onClose, refreshData }) => {
  const { getAccessToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFinish = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await createChatbot(accessToken, {
        name: 'Roxxy',
        avatar: 'https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/Roxxy.webp',
        image: 'https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/Roxxy.webp',
        personality: 'Website Resource Guide',
        industry: 'ecommerce', 
        trait: '"Train me to answer customer questions 24/7!"',
      });
      console.log('Chatbot created:', response);
      await refreshData();
      onClose();
      console.log('Navigating to:', 'edit-chatbot/' + response.chatbot_id);
      navigate(`/edit-chatbot/${response.chatbot_id}`);
    } catch (error) {
      console.error('Error creating chatbot:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container bg-gray-900 text-white relative overflow-y-auto p-6 md:p-6 rounded-lg shadow-lg">
      <button className="absolute top-4 right-4 text-gray-400 hover:text-gray-200" onClick={onClose}>
        <FaTimes size={20} />
      </button>
      <h2 className="text-2xl font-bold mb-6">Create New Assistant</h2>
      <div className="text-center">
        <button
          onClick={handleFinish}
          className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-full hover:bg-gradient-to-l transition duration-300 ease-in-out mt-4"
        >
          Create Your Assistant
        </button>
      </div>
      {loading && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
        </div>
      )}
    </div>
  );
};

export default GuidedChatbotSetupUI;
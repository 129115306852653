import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, signIn, isLoading } = useAuth();

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen bg-gray-900"> <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div></div> // Show a loading message or spinner while checking authentication status
  }

  if (!isAuthenticated) {
    signIn(); // Trigger Auth0 login/register screen
    return null; // Prevent rendering the component while redirecting
  }

  return element;
};

export default ProtectedRoute;

import React, { createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently, user, isLoading } = useAuth0();

  const signIn = () => loginWithRedirect();
  const signOut = () =>   logout({
    returnTo: window.location.origin,
    federated: true, // This will log the user out of the identity provider as well
  });


  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (error) {
      console.error('Error fetching access token:', error);
      return null;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, signIn, signOut, getAccessToken, user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Check, AlertCircle } from 'lucide-react';
import { createCheckoutSession, fetchSubscriptionStatus } from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const PricingCard = ({ plan, isAnnual, onStartCheckout, isCurrentPlan, freeTrialMessage }) => {
  const [isHovered, setIsHovered] = useState(false);


  const annualDiscount = 0.6; // 40% discount for annual plans
  const monthlyPrice = parseFloat(plan.price.replace('$', ''));
  const annualPrice = (monthlyPrice * 12 * annualDiscount).toFixed(2);

  return (
    <div
      className={`flex flex-col border rounded-lg shadow-md transition-transform duration-200 ${isHovered ? 'transform scale-105' : ''} ${plan.popular ? 'bg-gray-800 text-white' : 'bg-gray-800 text-white'} ${isCurrentPlan ? 'border-4 border-yellow-500' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Header Section */}
      <div className="p-4">
        <h3 className="text-lg font-semibold">{plan.title}</h3>
        {plan.popular && (
          <span className="bg-yellow-300 text-black rounded-full px-2 text-sm">Most Popular</span>
        )}
        {isCurrentPlan && freeTrialMessage && (
          <span className="block text-yellow-300 text-sm mt-2">{freeTrialMessage}</span>
        )}
      </div>

      {/* Content Section */}
      <div className="p-4 flex-grow">
        <div className="text-2xl font-bold">
          {plan.price === 'Contact Us' ? 'Contact Us' : `$${isAnnual ? annualPrice : monthlyPrice}`}
          {plan.price !== 'Contact Us' && (
            <span className="text-sm text-gray-300">/{isAnnual ? 'year' : 'month'}</span>
          )}
        </div>
        <ul className="mt-4 space-y-2">
          {plan.features.map((feature, idx) => (
            <li key={idx} className="flex items-center">
              <Check className="text-purple-500" size={20} />
              <span className="ml-2">{feature.text}</span>
              {feature.tooltip && (
                <div className="ml-2 relative">
                  <AlertCircle className="text-gray-400" size={16} />
                  <span className="absolute left-0 w-32 bg-gray-700 text-white text-xs rounded p-1 hidden tooltip-text">
                    {feature.tooltip}
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Button Section */}
      <div className="p-4 mt-auto">
        <button
          className={`w-full text-white font-bold py-2 rounded-full ${plan.title === 'Hobby' || plan.title === 'Enterprise' ? 'bg-[#1f2937] border border-white' : 'bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8]'}`}
          onClick={() => onStartCheckout(plan.title)}
        >
          {plan.price === 'Contact Us' ? 'Contact Us' : 'Get Started'}
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [isAnnual, setIsAnnual] = useState(false);
  const [userSubscriptionType, setUserSubscriptionType] = useState(null);
  const [freeTrialMessage, setFreeTrialMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStatus = async () => {
      if (isAuthenticated && user) {
        try {
          const userSubscription = await fetchSubscriptionStatus(user.sub);
          const subType = userSubscription.subscriptions[0].subscription_type;
          setUserSubscriptionType(subType);

          if (subType === 'free_trial') {
            const endDate = new Date('2024-12-31');
            const today = new Date();
            const differenceInTime = endDate - today;
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

            if (differenceInDays > 0) {
              setFreeTrialMessage(`You are currently on a free business plan trial until end of year (${differenceInDays} days left).`);
            } else {
              setFreeTrialMessage('Your free trial has ended.');
            }
          }
        } catch (error) {
          console.error('Error fetching subscription status:', error);
        }
      }
    };

    fetchStatus();
  }, [isAuthenticated, user]);

  const plans = [
    {
      title: 'Hobby',
      price: '$19.99',
      features: [
        { text: '100 conversation responses/month' },
        { text: '500 MB storage for training data' },
        { text: 'Customize the design' },
        { text: 'Generate AI Avatars' },
        { text: 'Deploy via QR Code' },
        { text: 'Deploy via Public Link' },
  
      ],
    },
    {
      title: 'Business',
      price: '$199.99',
      popular: true,
      features: [
        { text: '10,000 conversation responses/month' },
        { text: '5 GB storage for training data' },
        { text: 'Auto-learning from your web content' },
        { text: 'English and Spanish language options' },
        { text: 'Deploy via JS widget (Shopify, Wix, Wordpress, HTML)' },
        { text: 'Deploy via REST API' },
        // { text: 'Deploy via Email' },
        // { text: 'Deploy via SMS' },
        // { text: 'Deploy via Voice Assistant' },
        { text: '24/7 Customer Support' }      
      ],
    },
    {
      title: 'Enterprise',
      price: 'Contact Us',
      features: [
        { text: 'Dedicated success team & account manager' },
        { text: 'Unlimited AI interactions across all channels' },
        { text: 'Multi-language support with 30+ languages' },
        { text: 'Custom LLM fine-tuning & prompt engineering' },
        { text: 'Advanced analytics & business intelligence' },
        { text: 'Multi-platform deployment (Web, Mobile, SMS, Email, Slack)' },
        { text: 'Private cloud deployment options' },
        { text: 'Custom AI model training & optimization' }
      ],
    }
  ];

  const handleStartCheckout = async (planTitle) => {
    if (isLoading) {
      toast.info('Checking authentication status, please wait...');
      return;
    }

    if (!isAuthenticated) {
      toast.error('You need to log in to subscribe.');
      return;
    }

    const userId = user.sub; // Auth0 user ID
    const email = user.email; // User's email from Auth0
    const name = user.name || user.nickname; // User's name or nickname from Auth0
    if (planTitle === 'Business' || planTitle === 'Hobby') {
      try {
        const { url } = await createCheckoutSession(userId, email, name, planTitle); 
        window.location.href = url;
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    } else if (planTitle === 'Enterprise') {
      navigate('/contact');
    } else {
      navigate('/contact');
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-white text-center">Pricing</h2>
        {userSubscriptionType && (
          <div className="text-center text-white mb-4">
            <p>You are currently subscribed to the <strong>{userSubscriptionType.charAt(0).toUpperCase() + userSubscriptionType.slice(1)}</strong> plan.</p>
          </div>
        )}

<div className="mb-8 mt-8 p-4 bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white text-center rounded-lg">
          <p className="text-md font-semibold">Early adopters use code <span className="font-mono">"earlyadopters123"</span> before the end of the year to get 60% off for life!</p>
        </div>

        <div className="flex justify-center mb-4">
          <span className="mr-2 text-white">Monthly</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only"
              checked={isAnnual}
              onChange={() => setIsAnnual(!isAnnual)}
            />
            <div className="w-10 h-4 bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] rounded-full shadow-inner"></div>
            <div className={`dot absolute left-0 w-6 h-6 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${isAnnual ? 'translate-x-full' : 'translate-x-0'}`}></div>
          </label>
          <span className="ml-2 text-white">Annual (40% off)</span>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {plans.map((plan, index) => (
            <PricingCard
              key={index}
              plan={plan}
              isAnnual={isAnnual}
              onStartCheckout={handleStartCheckout}
              isCurrentPlan={userSubscriptionType === plan.title.toLowerCase() || (userSubscriptionType === 'free_trial' && plan.title === 'Business')}
              freeTrialMessage={userSubscriptionType === 'free_trial' && plan.title === 'Business' ? freeTrialMessage : ''}
            />
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Pricing;
import React, { useEffect, useRef } from 'react';
import QuickReplies from './QuickReplies';

const MessageList = ({ messages, avatar, setInput, themeColor, darkMode, handleSend, quickReplies }) => {
  const lastAssistantMessageRef = useRef(null);

  useEffect(() => {
    if (lastAssistantMessageRef.current && messages.length > 1) {
      lastAssistantMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className={`overflow-y-auto p-4 space-y-4 ${darkMode ? 'bg-gray-900' : 'bg-white'}`} >
      {messages.filter(msg => msg.content.trim() !== '').map((msg, index) => {
        const isUserMessage = msg.role === 'user';
        const isAssistantMessage = msg.role === 'assistant';

        const backgroundStyle = isUserMessage ? {
          backgroundColor: /^#[0-9A-F]{6}$/i.test(themeColor) ? themeColor : 'transparent',
          backgroundImage: themeColor.startsWith('linear-gradient') ? themeColor : 'none',
        } : {
          backgroundColor: darkMode ? '#4B5563' : '#F3F4F6',
          backgroundImage: 'none',
        };

        return (
          <div key={index} className={`flex items-end ${isUserMessage ? 'justify-end' : 'justify-start'}`} ref={isAssistantMessage ? lastAssistantMessageRef : null}>
            {isAssistantMessage && (
              <img src={avatar} alt="Assistant Avatar" className={`w-8 h-8 rounded-full mr-2 mb-2 ${darkMode ? 'bg-gray-700' : ''}`} />
            )}
            <div className={`whitespace-pre-wrap px-4 py-2 rounded-lg shadow ${isUserMessage ? 'rounded-br-none' : 'rounded-bl-none'} ${darkMode ? 'text-gray-200' : 'text-black'}`} style={backgroundStyle}>
              {msg.content}
            </div>
          </div>
        );
      })}
      {messages.length <= 1 && (
        <QuickReplies setInput={setInput} themeColor={themeColor} handleSend={handleSend} quickReplies={quickReplies} />
      )}
      <div className="flex flex-col items-center cursor-pointer p-8">
        <a href="https://smarterchatbots.com" className="text-center">
          <span className="text-sm text-gray-500">Powered by</span>
          <img
            alt="Your Company"
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/assets/SmarterChatbotsLogo.svg"
            className="h-6 w-auto mt-1"
          />
        </a>
      </div>
    </div>
  );
};

export default MessageList;
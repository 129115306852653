import React, { useEffect, useState } from 'react';

const ChatbotHeader = ({
  avatar,
  bot_name,
  position,
  themeColor,
  onReset,
  onToggleDarkMode,
  language = 'en',
  // setLanguage,
  darkMode,
  handleSend,
  setInput, 
  onMinimize
  
}) => {
  const [isDarkMode, setIsDarkMode] = useState(darkMode);

  useEffect(() => {
    setIsDarkMode(darkMode);
  }, [darkMode]);

 

  return (
    <div className={`relative overflow-hidden ${isDarkMode ? 'dark' : ''}`}>
      <div className={`transition-colors duration-300`} style={{ background: themeColor || '#4F46E5' }}>
        <div className={`flex justify-end items-center space-x-3 p-2 bg-opacity-50 bg-black dark:bg-gray-900 transition-all duration-300`}>
          <select 
            value={language} 
            onChange={(e) => {
              if (e && e.target && e.target.value) {
                if(e.target.value == "es"){
                  console.log('es');
                  handleSend("Reply in Spanish");
                }  else {
                  console.log('en'); 
                  handleSend("Reply in English");
                }
                // setLanguage(e.target.value);
              }
            }} 
            className={`bg-transparent text-white dark:text-gray-200 text-sm focus:outline-none cursor-pointer`}
          >
            <option value="en" className="text-gray-900 dark:text-white">🇺🇸</option>
            <option value="es" className="text-gray-900 dark:text-white">🇪🇸</option>
          </select>
          <button 
            onClick={onReset} 
            className={`text-white hover:text-gray-200 focus:outline-none transition-colors duration-200`} 
            aria-label="Reset Conversation"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
          </button>
          <button 
            onClick={() => {
              onToggleDarkMode();
              setIsDarkMode(!isDarkMode);
            }} 
            className={`text-white hover:text-gray-200 focus:outline-none transition-colors duration-200`} 
            aria-label="Toggle Dark Mode"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
            </svg>
          </button>
          <button onClick={onMinimize} className="text-white" aria-label="Minimize Chatbot">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14" />
            </svg>
          </button>
        </div>
        <div className={`flex items-center space-x-2 p-2`}>  
          <div className="relative">  
            <div className={`absolute inset-0 rounded-full border-4 transition-all duration-300 ${isDarkMode ? 'border-gradient-dark animate-pulse' : 'border-gradient-light animate-pulse'}`}></div>
            <div className={`w-16 h-16 rounded-full overflow-hidden ${isDarkMode ? 'shadow-lg' : 'shadow-md'}`}>  
              <img src={avatar} alt={`${bot_name} avatar`} className="w-full h-full object-cover" />
            </div>
          </div>
          <div>
            <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>{bot_name}</h2>
            <p className={`text-sm ${isDarkMode ? 'text-white' : 'text-black'}`}>{position}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotHeader;
import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { User, MessageCircle, ThumbsUp, Clock, TrendingUp, AlertCircle, ChevronDown, ChevronUp, MessageCircleWarning, Zap, Smile, LucideBookOpenText} from 'lucide-react';
import Chatbot from './Chatbot/Chatbot';
import { useAuth } from '../context/AuthContext';
import { fetchAllConversations } from '../../services/api';

const Overview = ({ chatbotData }) => {
  const [timeRange, setTimeRange] = useState('hour');
  const [expandedStat, setExpandedStat] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [averageConversationReply, setAverageConversationReply] = useState(0);
  const [averageChatbotResponseTime, setAverageChatbotResponseTime] = useState(0);
  const [performanceData, setPerformanceData] = useState([]);
  const [improvementSuggestions, setImprovementSuggestions] = useState([]);

  const { getAccessToken } = useAuth();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const accessToken = await getAccessToken();
        const response = await fetchAllConversations(accessToken, chatbotData.chatbot_id);
        const data = await response.json();
        console.log("testing fetch convos...")
        console.log(data);
        setConversations(data);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };
    fetchConversations();
  }, [chatbotData]);

  useEffect(() => {
    const calculateAverageConversationReply = () => {
      let totalUserReplies = 0;
      conversations.forEach(conversation => {
        conversation.chat_history.forEach(message => {
          if (message.role === "user") {
            totalUserReplies += 1;
          }
        });
      });
      const averageUserReplies = totalUserReplies / conversations.length;
      return averageUserReplies.toFixed(2) || 0;
    };

    const calculateChatbotResponseTime = () => {
      let total_response_time = 0; 
      let total_responses = 0;
      conversations.forEach(conversation => {
        conversation.chat_history.forEach(message => {
          if (message.role === "assistant" && message.duration) {
            const responseTime = parseFloat(message.duration);
            if (!isNaN(responseTime)) {
              total_response_time += responseTime;
              total_responses += 1;
            }
          }
        });
      });
      const averageResponseTime = total_responses > 0 ? (total_response_time / total_responses).toFixed(2) : 0;
      return averageResponseTime;
    };

    if (conversations.length > 0) {
      const aveConvoReplies = calculateAverageConversationReply();
      setAverageConversationReply(aveConvoReplies);
      const rt = calculateChatbotResponseTime();
      setAverageChatbotResponseTime(rt);
    } else {
      setAverageConversationReply(0);
      setAverageChatbotResponseTime(0);
    }
  }, [conversations]);

  useEffect(() => {
    const countConversationsByTimeRange = () => {
      const today = new Date();
      const hourCounts = Array(24).fill(0);
      const dayCounts = Array(7).fill(0);
      const monthCounts = Array(today.getDate()).fill(0);
      const yearCounts = Array(12).fill(0);

      conversations.forEach(conversation => {
        const createdDateUTC = new Date(conversation.created_at);
        const createdDateLocal = new Date(createdDateUTC.getTime() - createdDateUTC.getTimezoneOffset() * 60000);

        if (createdDateLocal.toDateString() === today.toDateString()) {
          // Count by hour
          const hour = createdDateLocal.getHours();
          hourCounts[hour] += 1;
        }

        if (createdDateLocal >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
          // Count by day of the week
          const day = createdDateLocal.getDay();
          dayCounts[day] += 1;
        }

        if (createdDateLocal.getMonth() === today.getMonth() && createdDateLocal.getFullYear() === today.getFullYear()) {
          // Count by day of the month
          const day = createdDateLocal.getDate() - 1;
          monthCounts[day] += 1;
        }

        if (createdDateLocal.getFullYear() === today.getFullYear()) {
          // Count by month of the year
          const month = createdDateLocal.getMonth();
          yearCounts[month] += 1;
        }
      });

      let performanceData;

      switch (timeRange) {
        case 'hour':
          performanceData = hourCounts.map((count, hour) => ({
            name: `${hour}:00`,
            interactions: count,
          }));
          break;
        case 'day':
          performanceData = dayCounts.map((count, index) => ({
            name: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][index],
            interactions: count,
          }));
          break;
        case 'month':
          performanceData = monthCounts.map((count, day) => ({
            name: day + 1,
            interactions: count,
          }));
          break;
        case 'year':
          performanceData = yearCounts.map((count, month) => ({
            name: new Date(0, month).toLocaleString('en-US', { month: 'long' }),
            interactions: count,
          }));
          break;
        default:
          performanceData = [];
      }

      setPerformanceData(performanceData);
    };

    countConversationsByTimeRange();
  }, [conversations, timeRange]);

  useEffect(() => {
    const suggestions = [];
    if (!chatbotData.initial_message) {
      suggestions.push({
        icon: MessageCircleWarning,
        message: "Set a greeting message to guide users effectively."
      });
    }

    if (!chatbotData.short_term_memory) {
      suggestions.push({
        icon: Smile,
        message: "Train your assistant's response style on how to engage with your audience."
      });
    }
    if (chatbotData.knowledgeSourceSet !== true) {
      suggestions.push({
        icon: LucideBookOpenText,
        message: "You must set a knowledge source to enable your assistant to chat."
      });
    }

      if (!chatbotData.quick_replies) {
      suggestions.push({
        icon: Zap,
        message: "Add at least 1 quick reply so people know where to start."
      });
    } else {
      const nonEmptyQuickReplies = chatbotData.quick_replies.filter(reply => reply.shortReply.trim() !== "");
      if (nonEmptyQuickReplies.length < 1) {
        suggestions.push({
          icon: Zap,
          message: "We recommend at least 1 quick reply so people know where to start."
        });
      } else if (nonEmptyQuickReplies.length < 2) {
        suggestions.push({
          icon: AlertCircle,
          message: "You have less than 2 quick replies. Consider adding more options for users."
        });
      }
    }

    setImprovementSuggestions(suggestions);
  }, [chatbotData]);

  const stats = [
    { title: "Total Conversations", value: `${conversations.length}`, icon: MessageCircle, details: "Total number of chatbot conversations." },
    { title: "Average Conversation Replies", value: `${averageConversationReply}`, icon: Clock, details: "Average number of replies users send to chatbot." },
    { title: "Chatbot Response Time", value:`${averageChatbotResponseTime}`, icon: ThumbsUp, details: "Average time it takes for your chatbot to generate a response. Does not include network delays." },
    { title: "Conversation Handoffs to Humans", value: "0", icon: User, details: "No handoffs yet." },
  ];

  const gradientTextClass = "bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-transparent bg-clip-text";
  const gradientBgClass = "bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8]";

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <h2 className={`text-3xl font-bold mb-6 ${gradientTextClass}`}>Chatbot Overview</h2>

          {/* Magic Suggestions */}
          <div className="mt-6 bg-gray-800 rounded-lg p-4 mb-6">
            <h3 className="text-xl font-semibold mb-1 bg-clip-text text-transparent bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] truncate max-w-[200px]">Magic Suggestions</h3>
            <p className="text-gray-300 mb-4 text-sm">Here are some suggestions to enhance your assistants's performance. These will disappear once addressed.</p>
                        <ul className="space-y-2">
              {improvementSuggestions.map((suggestion, index) => (
                <li key={index} className="flex items-center text-transparent bg-clip-text bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8]">
                  <suggestion.icon className="mr-2 text-[#00c4cc]" size={20} />
                  <span>{suggestion.message}</span>
                </li>
              ))}
            </ul>
          </div>

      {/* Key Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
        {stats.map((stat, index) => (
          <div key={index} className="bg-gray-800 p-4 rounded-lg shadow col-span-1">
            <div className="flex items-center justify-between cursor-pointer" onClick={() => setExpandedStat(expandedStat === index ? null : index)}>
              <div className="flex items-center">
                <stat.icon className={`${gradientTextClass} mr-3`} size={24} />
                <div>
                  <p className="text-sm text-gray-400">{stat.title}</p>
                  <p className={`text-2xl font-bold ${gradientTextClass}`}>{stat.value}</p>
                </div>
              </div>
              {expandedStat === index ? <ChevronUp className={gradientTextClass} size={20} /> : <ChevronDown className={gradientTextClass} size={20} />}
            </div>
            {expandedStat === index && (
              <p className="mt-2 text-sm text-gray-400">{stat.details}</p>
            )}
          </div>
        ))}
      </div>
      
      {/* Chatbot and Performance Chart */}
      <div className="flex flex-col lg:flex-row gap-6 mb-6">
        {/* Left Column: Live Chatbot */}
        <div className="lg:w-2/5 bg-gray-800 rounded-lg p-4">
          <h3 className={`text-xl font-semibold mb-4 ${gradientTextClass}`}>Live Chatbot</h3>
          <div className="w-[300px] h-[400px] bg-transparent rounded-lg shadow-lg relative">
            <Chatbot chatbotId={chatbotData.chatbot_id} isClosed={false}/>
          </div>
        </div>
        
        {/* Right Column: Performance Chart */}
        <div className="lg:w-3/5 bg-gray-800 rounded-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className={`text-xl font-semibold ${gradientTextClass}`}>Performance Overview</h3>
            <select 
              className="bg-gray-700 text-gray-300 rounded px-2 py-1"
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
            >
              <option value="hour">This Hour</option>
              <option value="day">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
            </select>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={performanceData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: 'none',
                  borderRadius: '0.375rem',
                  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                }}
                itemStyle={{ color: '#E5E7EB' }}
                labelStyle={{ color: '#9CA3AF' }}
              />
              <Line type="monotone" dataKey="interactions" name="Interactions" stroke="#00c4cc" strokeWidth={2} dot={{ r: 4 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Overview;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ChatbotCard from '../molecules/ChatbotCard';
import { fetchAllUserChatbots } from '../../services/api';
import { createChatbot } from '../../services/api';

const Dashboard = () => {
  const [chatbots, setChatbots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { isAuthenticated, getAccessToken } = useAuth();

  const fetchChatbots = useCallback(async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        const data = await fetchAllUserChatbots(accessToken);
        setChatbots(data);
      }
    } catch (error) {
      alert('Error fetching chatbots: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessToken]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchChatbots();
    }
  }, [isAuthenticated, fetchChatbots]);

  const handleCreateChatbot = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await createChatbot(accessToken, {
        name: 'Roxxy',
        avatar: 'https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/Roxxy.webp',
        image: 'https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/Roxxy.webp',
        trait: '"Train me to answer customer questions 24/7!"',
      });
      console.log('Chatbot created:', response);
      console.log('Navigating to:', 'edit-chatbot/' + response.chatbot_id);
      navigate(`/edit-chatbot/${response.chatbot_id}?tab=branding`);
        } catch (error) {
      console.error('Error creating chatbot:', error);
    } finally {
      console.log("Loading false");
    }
  };

  const handleEditChatbot = (chatbotId) => {
    navigate(`/edit-chatbot/${chatbotId}`);
  };

  return (
    <div className="bg-gray-900 text-white p-4 h-full" style={{minHeight: '1000px'}}>
      <div className="flex justify-end my-4">
        <button 
          className="px-6 py-2 rounded-full bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold hover:from-blue-600 hover:to-purple-700 transition duration-300 ease-in-out"
          onClick={handleCreateChatbot}
        >
          + New Assistant
        </button>
      </div>
      <h2 className="text-center text-2xl font-bold mb-4">Your Assistants</h2>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen bg-gray-900">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-1">
          {chatbots.length > 0 ? (
            chatbots.map((chatbot, index) => (
              <div key={index} className="w-full">
                <ChatbotCard 
                  chatbot={chatbot} 
                  onClick={() => handleEditChatbot(chatbot.chatbot_id)}
                  showConversationsOnly={true}
                />
              </div>
            ))
          ) : (
            <p className="text-center text-gray-400">No chatbots created yet. Click "Create Chatbot" to get started!</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook
import { fetchSubscriptionStatus } from '../../services/api'; // Import the API function
import axios from 'axios'; // Import axios for making API requests
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Subscription = () => {
    const { user } = useAuth(); // Get the user information from the auth context
    const [subscriptionType, setSubscriptionType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStatus = async () => {
            if (user) {
                try {
                    const userSubscription = await fetchSubscriptionStatus(user.sub);
                    const subType = userSubscription.subscriptions[0].subscription_type;
                    setSubscriptionType(subType);
                    console.log('Subscription Type:', subType);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                }
            }
        };

        fetchStatus();
    }, [user]);

    const handleCancelSubscription = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_REST_API}/payments/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(String(user.sub)) // Send user_id as a plain string
            });
    
            if (response.ok) {
                alert('Subscription canceled successfully');
                setSubscriptionType('free_trial');
            } else {
                const errorData = await response.json();
                console.error('Error canceling subscription:', errorData);
                alert('Failed to cancel subscription');
            }
        } catch (error) {
            console.error('Error canceling subscription:', error);
            alert('Failed to cancel subscription');
        }
    };

    const handleUpgrade = () => {
        navigate('/pricing');
    };

    console.log('User:', user);
    console.log('Current Subscription Type:', subscriptionType);
    return (
        <div className="w-full h-full mx-auto p-4 bg-gray-900 text-white" style={{minHeight: '1000px'}}>
            <h1 className="text-2xl font-bold mb-4">Account Information</h1>
            <div className="bg-gray-800 shadow-md rounded-lg p-4">
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">Personal Information</h2>
                    {user.picture && (
                        <img src={user.picture} alt="User" className="w-24 h-24 rounded-full mb-4" />
                    )}
                    <p><strong>Name:</strong> {user.name}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p><strong>Email Verified:</strong> {user.email_verified ? 'Yes' : 'No'}</p>
                </div>
                <div>
                    <h2 className="text-xl font-semibold">Subscription</h2>
                    <p><strong>Type:</strong> {subscriptionType}</p>
                    {subscriptionType !== "free_trial" && (
                        <button
                            onClick={handleCancelSubscription}
                            className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                        >
                            Cancel Subscription
                        </button>
                    )}
                    {['free_trial', 'hobby', 'business'].includes(subscriptionType) && (
                        <div className="mt-4">
                            <p>Please upgrade your subscription for more features.</p>
                            <button
                                onClick={handleUpgrade}
                                className="mt-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                Upgrade Now
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Subscription;
// src/api/api.js
export const fetchSubscriptionStatus = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/users/subscription_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch subscription status');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      throw error; // Propagate the error
    }
  };

export const fetchChatbotData = async (chatbotId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/public/chatbot/info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chatbot_id: chatbotId }), // Pass the chatbotId to the API
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch chatbot data: ${response.statusText}`);
    }

    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data format received from the server');
    }

    return data;
  } catch (error) {
    console.error('Error fetching chatbot data:', error);
    throw error; // Propagate the error
  }
};

export const sendMessage = async (userInput, conversationId, chatbotId, messageHistory = []) => {
  const messageData = {
    user_input: userInput,
    conversation_id: conversationId, // Ensure this is provided
    message_history: messageHistory,   // Default to an empty array if not provided
    chatbot_id: chatbotId              // Ensure the chatbotId is passed
  };
  // console.log(messageData)
  try {
    console.log("Sending chat message from api.js");
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/public/chatbot/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageData),
    });

    if (!response.ok) {
      throw new Error(`Failed to send message: ${response.statusText}`);
    }
    const res = await response.json();
    console.log(res)
    return res
    
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; // Propagate the error for further handling
  }
};
export const sendPublicMessage = async (userInput, conversationId, chatbotId, messageHistory, language) => {
  const message = {
    user_input: userInput,
    conversation_id: conversationId, 
    chatbot_id: chatbotId, 
    message_history:messageHistory, 
    language:language
  }
  // console.log(message);
  
  try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/public/chatbot/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
    
    if (!response.ok) {
      throw new Error(`Failed to send message: ${response.statusText}`);
    }
    return await response.json();
    
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; // Propagate the error
  }
};

export const editChatbotData = async (accessToken, updatedData) => {
  try {
      await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${updatedData.chatbotId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify(updatedData),
        });
        console.log('Chatbot updated successfully');
      } catch (error) {
          console.log('Error updating chatbot: ' + error.message);
      }
  };

export const deleteChatbotData = async (accessToken, chatbotId) => {
    await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${chatbotId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        });
        console.log('Chatbot deleted successfully');
};

export const fetchAllConversations = async (accessToken, chatbotId) => {
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${chatbotId}/conversations`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        });
    return response;
}
    
export const deleteSingleConversation = async(accessToken, chatbotId, conversationToDelete) => {
    await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${chatbotId}/conversations/${conversationToDelete}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
}

export const fetchKnowledgeBaseData = async(accessToken, chatbotId) => {
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/get_combined_scraped_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ chatbot_id: chatbotId }),
      });

    return await response.json();
}

export const initiateCrawlAgent = async(accessToken, url) => {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/crawl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ url }),
      });
      return await response.json();
}

// export const addKnowledgeSourceToChatbot = async(accessToken, scrape_id, chatbotId) => {
//     const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/save_scraped_id_to_chatbot_knowledgebase`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({ scrape_id: scrape_id, chatbotId: chatbotId }),
//       });
//     if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(`Failed to save scrape ID. Status: ${response.status}. Error: ${errorData.detail}`);
//     }
//     return await response.json();
// }

// export const removeKnowledgeSourceFromChatbot = async(accessToken, scrapeId, chatbotId) => {
//     const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/remove_scraped_id`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({ chatbot_id: chatbotId, scrapeId }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(`Failed to remove scrape ID. Status: ${response.status}. Error: ${errorData.detail}`);
//       }

//       const data = await response.json();

// }

// export const modifyKnowledgeSource = async(accessToken, scrapeId, editedData) => {
//     const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/modify_scraped_id_data`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({
//           scrape_id: scrapeId,
//           modified_data: JSON.parse(editedData) // Parse back to object before sending
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(`Failed to save changes. Status: ${response.status}. Error: ${errorData.detail}`);
//       }

//       return await response.json();
// }

export const createChatbot = async(accessToken, stepData)=>{
  try{
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(stepData),
    });
    console.log(`Response: ${response}`)

    if (response.ok) {
      console.log('Chatbot setup data saved successfully!');
      return await response.json(); 
      
    } else {
      const error = await response.json();
      console.log(`Failed to save chatbot setup data: ${error.detail}`);
    }
  } catch (error) {
    console.log('Error saving chatbot: ' + error.message);
  }
}

export const fetchAllUserChatbots = async (accessToken) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json(); 
      console.log('Response:');
      console.log(data);
      console.log(typeof(data));

      return data; // Return the parsed JSON data directly
    } else {
      const error = await response.json();
      console.log(`Failed to load chatbot data: ${error.detail}`);
    }
  } catch (error) {
    console.log('Error saving chatbot: ' + error.message);
  }
};

export const createCheckoutSession = async(userId, email, name, planTitle, discountPercentage) => {
  const response = await fetch(`${process.env.REACT_APP_REST_API}/payments/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: userId,
      email: email,
      name: name,
      plan_type: planTitle.toLowerCase(),
      discount_percentage: discountPercentage,
    }),
  });
  console.log("createCheckoutSession");
  console.log(response);
  return await response.json(); 
}

export const fetchSingleChatbotData = async(accessToken, chatbotId)=>{
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${chatbotId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Handle 401 Unauthorized specifically
        return null; // Stop further processing
      }
      throw new Error(`HTTP status ${response.status}`);
    }
    return await response.json();
}

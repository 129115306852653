import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { MdSchool, MdHistory, MdAnalytics, MdChevronLeft, MdChevronRight, MdChat, MdMenu } from 'react-icons/md';
import { useAuth } from '../context/AuthContext';
import { fetchSingleChatbotData } from '../../services/api';

// Import your components here
import ChatbotTraining from '../organisms/ChatbotTraining';
import ConversationHistory from '../organisms/ConversationHistory';
import Integrations from '../organisms/Integrations';
import Appearance from '../organisms/Appearance';
import Personality from '../organisms/Personality';
import Overview from '../organisms/Overview';
import Settings from '../organisms/Settings';
import Branding from '../organisms/Branding';

const ChatbotDashboardTemplate = () => {
  const { chatbotId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [chatbotData, setChatbotData] = useState(null);
  const { isAuthenticated, getAccessToken } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For mobile dropdown

  const fetchChatbotData = async () => {
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        const data = await fetchSingleChatbotData(accessToken, chatbotId);
        if (data) {
          setChatbotData(data);
        }
      }
    } catch (error) {
      console.error('Error fetching chatbot:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchChatbotData();
    }
  }, [chatbotId, isAuthenticated, getAccessToken]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    setActiveTab(tab || 'overview');
  }, [location]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
      setIsDropdownOpen(false);
    }
    navigate(`?tab=${tab}`);
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleDeployClick = () => {
    setActiveTab('integrations');
    navigate(`?tab=integrations`);
  };

  const navItems = [
    { id: 'overview', icon: MdAnalytics, label: 'Overview' },
    { id: 'branding', icon: MdChat, label: 'Branding' },
    { id: 'training', icon: MdSchool, label: 'Training' },
    { id: 'history', icon: MdHistory, label: 'History' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <Overview chatbotData={chatbotData} fetchChatbotData={fetchChatbotData} />;
      case 'branding':
        return <Branding chatbotData={chatbotData} setChatbotData={setChatbotData} fetchChatbotData={fetchChatbotData} />;
      case 'training':
        return <ChatbotTraining chatbotData={chatbotData} setChatbotData={setChatbotData} navigate={navigate} fetchChatbotData={fetchChatbotData} />;
      case 'history':
        return <ConversationHistory chatbotData={chatbotData} chatbotId={chatbotData?.chatbot_id} fetchChatbotData={fetchChatbotData} />;
      case 'integrations':
          return <Integrations chatbot={chatbotData} fetchChatbotData={fetchChatbotData} />;
      default:
        return null;
    }
  };

  if (!chatbotData) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 text-white h-screen flex flex-col lg:flex-row overflow-hidden">
      {/* Sidebar for larger screens */}
      <div
        className={`bg-gray-800 shadow-lg transition-all duration-300 ease-in-out hidden lg:flex flex-col
                    ${isSidebarOpen ? 'w-64' : 'w-16'}`}
      >
        <div className={`flex items-center justify-between p-4 border-b border-gray-700 
                         ${isSidebarOpen ? '' : 'justify-center'}`}>
          {isSidebarOpen && (
            <h2 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] truncate">
              AI Studio
            </h2>
          )}
          <button 
            onClick={toggleSidebar} 
            className="text-gray-300 hover:text-white focus:outline-none focus:text-white p-1"
          >
            {isSidebarOpen ? <MdChevronLeft size={24} /> : <MdChevronRight size={24} />}
          </button>
        </div>
        <nav className={`mt-4 flex-grow ${isSidebarOpen ? '' : 'flex flex-col items-center'}`}>
          {navItems.map((item) => (
            <button
              key={item.id}
              onClick={() => handleTabChange(item.id)}
              className={`flex items-center w-full py-2 px-4 transition-colors duration-200 
                          ${activeTab === item.id ? 'bg-blue-600' : 'hover:bg-gray-700'}
                          ${isSidebarOpen ? '' : 'justify-center'}`}
              title={item.label}
            >
              <item.icon className={isSidebarOpen ? "mr-3" : ""} size={20} />
              {isSidebarOpen && item.label}
            </button>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-gray-800 shadow-md z-10">
          <div className="flex items-center p-4">
            <nav className="text-sm breadcrumbs w-full flex flex-col md:flex-row md:justify-between items-center">
              <ul className="flex items-center">
                <li><Link to="/dashboard" className="text-blue-400 hover:underline">Dashboard</Link></li>
                <li className="mx-2">/</li>
                <li className="font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] truncate max-w-[200px]">
                  {chatbotData.name}
                </li>
              </ul>
        
              <button 
                className="bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-1 px-3 md:py-2 md:px-4 rounded-full shadow hover:from-[#00c4cc] hover:to-[#7c2ae8] transition duration-300 mt-2 md:mt-0"
                onClick={handleDeployClick}
              >
                Deploy Now
              </button>
            </nav>
          </div>
        </header>

        {/* Dropdown for mobile */}
        <div className="lg:hidden flex flex-col">
          <button
            className="text-white p-2 bg-gray-800 flex items-center justify-between"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdMenu size={24} />
              <span className='text-sm ml-2'>AI Studio</span>
            </div>
          </button>
          {isDropdownOpen && (
            <nav className="bg-gray-800">
              {navItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => handleTabChange(item.id)}
                  className={`flex items-center w-full py-2 px-4 transition-colors duration-200 
                              ${activeTab === item.id ? 'bg-blue-600' : 'hover:bg-gray-700'}`}
                >
                  <item.icon className="mr-3" size={20} />
                  {item.label}
                </button>
              ))}
            </nav>
          )}
        </div>
      
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-900">
          <div className="mx-auto py-8 px-2">
            {renderContent()}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChatbotDashboardTemplate;
import React, { useState, useEffect } from 'react';
import { AlertCircle, X } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { fetchSubscriptionStatus } from '../../services/api'; // Import the API function

const AnnouncementBar = () => {
  const navigate = useNavigate();
  const [announcement, setAnnouncement] = useState('Start your free trial today!');
  const [showBar, setShowBar] = useState(true);
  const [upgradeButtonText, setUpgradeButtonText] = useState('Start Free Trial');
  const [welcomeMessage, setWelcomeMessage] = useState('Welcome to our pre-launch!');
  const [redirectPath, setRedirectPath] = useState('/dashboard'); // Default redirect path
  const { user, isAuthenticated } = useAuth0();
  const [userSubscriptionType, setUserSubscriptionType] = useState(null);

  useEffect(() => {
    const fetchStatus = async () => {
      if (isAuthenticated && user) {
        try {
          console.log("Fetching subscription status...");
          const userSubscription = await fetchSubscriptionStatus(user.sub);
          const subType = userSubscription.subscriptions[0].subscription_type;
          setUserSubscriptionType(subType);
        
          console.log("User subscription type: ", subType);
          if (subType === 'free_trial') {
            const endDate = new Date('2024-12-31'); // Extended to end of year
            const today = new Date();
            const differenceInTime = endDate - today;
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  
            if (differenceInDays > 0) {
              setAnnouncement(`${differenceInDays} days left in your free trial. Upgrade before it ends for a 60% off lifetime offer!`);
            } else {
              setAnnouncement('Your competition access has ended.');
            }
            setRedirectPath('/pricing');
            setUpgradeButtonText('Upgrade Now');
            setShowBar(true);
  
          } else if (subType === 'hobby') {
            setAnnouncement('Unlock more features with our business plan!');
            setUpgradeButtonText('Upgrade to Business');
            setShowBar(true);
            setRedirectPath('/pricing');
  
          } else if (subType === 'business') {
            setAnnouncement('Need an account manager? Contact us for enterprise pricing.');
            setUpgradeButtonText('Upgrade to Enterprise');
            setShowBar(true);
            setRedirectPath('/contact');
  
          } else if (subType === 'enterprise') {
            setShowBar(false); // Hide bar for enterprise customers
          }
          
        } catch (error) {
          // Default message for new/non-subscribed users or errors
          setAnnouncement('Welcome to our pre-launch! Try our free trial today.');
          setUpgradeButtonText('Start Free Trial');
          setShowBar(true);
          setRedirectPath('/dashboard');
        }
      }
    };
  
    fetchStatus();
  }, [isAuthenticated, user]);
  
  const handleClose = () => {
    setShowBar(false);
  };

  const handleButtonClick = () => {
    navigate(redirectPath);
  };

  if (!showBar) return null;

  return (
    <div className="bg-gray-800 text-white p-4 flex flex-wrap justify-between items-center shadow-lg gap-4">
      <div className="flex items-center space-x-2">
        <AlertCircle size={16} />
        <span>{welcomeMessage} {announcement}</span>
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={handleButtonClick}
          className="bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-1 px-3 rounded-full"
        >
          {upgradeButtonText}
        </button>
        <button onClick={handleClose} className="text-gray-400 hover:text-white">
          <X size={16} />
        </button>
      </div>
    </div>
  );
};

export default AnnouncementBar;
import React, { useState } from 'react';

const ContestEntryForm = () => {
    const [formData, setFormData] = useState({
        chatbotLink: '',
        inspiration: '',
        videoLink: '',
        contactInfo: ''
    });
    const [responseMessage, setResponseMessage] = useState(''); // Store the response message
    const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission status
    const [formSubmitted, setFormSubmitted] = useState(false); // Track if the form has been submitted

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Indicate that the form is submitting

        const form = new FormData();
        form.append('chatbotLink', formData.chatbotLink);
        form.append('inspiration', formData.inspiration);
        form.append('videoLink', formData.videoLink);
        form.append('contactInfo', formData.contactInfo);

        fetch('https://script.google.com/macros/s/AKfycbyuaOgSosj7l4E4Pc_sQ6YPgRE049EFZar_4ajtRRx8zBMpAd-0h0eOySzcilqBd4H85g/exec', {
            method: 'POST',
            body: form,
        })
        .then((response) => response.text())
        .then((text) => {
            // Fun congratulatory message
            setResponseMessage('🎉 Congratulations! 🎉 Your assistant has been successfully entered into the contest. 🥳 Just so you know, you can add up to 3 assistants into this contest, just use a different submission for each. Thank you for entering! 💪');
            setIsSubmitting(false); // Form submission complete
            setFormSubmitted(true); // Hide the form after submission
        })
        .catch((error) => {
            setResponseMessage('Error submitting form: ' + error.message); // Error message
            setIsSubmitting(false); // Allow resubmission in case of failure
        });
    };

    return (
        <div className="flex justify-center items-center bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white p-2 rounded-lg shadow-lg">
            {/* Display the response message if available */}
            {responseMessage && (
                <div className="mb-4 p-4 bg-green-500 text-white rounded-lg text-center font-bold">
                    {responseMessage}
                </div>
            )}

            {/* Conditionally render the form if it has not been submitted */}
            {!formSubmitted && (
                <form 
                    onSubmit={handleSubmit} 
                    className="bg-gray-800 p-2 rounded-lg shadow-lg w-full"
                >
                    <h2 className="text-3xl font-bold mb-6 text-center">Contest Entry Form</h2>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2" htmlFor="chatbotLink">
                            Chatbot Link
                        </label>
                        <input 
                            type="url" 
                            id="chatbotLink" 
                            name="chatbotLink" 
                            value={formData.chatbotLink} 
                            onChange={handleChange} 
                            className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none"
                            required 
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2" htmlFor="inspiration">
                            Inspiration Behind It
                        </label>
                        <textarea 
                            id="inspiration" 
                            name="inspiration" 
                            value={formData.inspiration} 
                            onChange={handleChange} 
                            className="w-full p-2 rounded bg-gray-700 text-white  focus:outline-none"
                            rows="4"
                            required 
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2" htmlFor="contactInfo">
                            Contact Info (LinkedIn, Email, etc.)
                        </label>
                        <input 
                            type="text" 
                            id="contactInfo" 
                            name="contactInfo" 
                            value={formData.contactInfo} 
                            onChange={handleChange} 
                            className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none"
                            required 
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2" htmlFor="videoLink">
                            Optional Video Link (YouTube or Loom)
                        </label>
                        <input 
                            type="url" 
                            id="videoLink" 
                            name="videoLink" 
                            value={formData.videoLink} 
                            onChange={handleChange} 
                            className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none"
                        />
                    </div>

                    <div className="flex justify-center">
                        <button 
                            type="submit" 
                            className={`px-6 py-2 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold shadow-lg hover:from-blue-700 hover:to-purple-800 transition-all duration-300 ${isSubmitting ? 'opacity-50' : ''}`}
                            disabled={isSubmitting} // Disable the button while submitting
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit Entry'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ContestEntryForm;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { fetchAllConversations, deleteSingleConversation } from '../../services/api';

const ConversationHistory = ({ chatbotData, chatbotId }) => {
  const [conversations, setConversations] = useState([]);
  const [expandedConversations, setExpandedConversations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const { getAccessToken } = useAuth();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const accessToken = await getAccessToken();
        const response = await fetchAllConversations(accessToken, chatbotId);
        const data = await response.json();
        // Sort conversations by created_at date in descending order
        const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setConversations(sortedData);
        for (let conversation of conversations) {
          console.log('conversation.conversation_id:', conversation.conversation_id);
          console.log('conversation.chat_history:', conversation.chat_history);
          console.log('conversation.created_at:', conversation.created_at);

        }
        
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
  }, [chatbotData, chatbotId, getAccessToken]);

  const handleToggleExpand = (conversationId) => {
    setExpandedConversations(prev => 
      prev.includes(conversationId) ? prev.filter(id => id !== conversationId) : [...prev, conversationId]
    );
  };

  const handleDeleteClick = (conversationId) => {
    setConversationToDelete(conversationId);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const accessToken = await getAccessToken();
      await deleteSingleConversation(accessToken, chatbotId, conversationToDelete);
      setConversations(conversations.filter(conv => conv.conversation_id !== conversationToDelete));
      setShowModal(false);
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  return (
    <div className="p-6 bg-gray-800 text-white rounded-lg shadow-lg">
      <h4 className="text-2xl font-bold mb-4">Conversation History</h4>
      <div className="space-y-4">
        {conversations.map((conversation) => (
          <div key={conversation.conversation_id} className="bg-gray-700 rounded-md p-4 transition-shadow hover:shadow-lg">
            <button
              onClick={() => handleToggleExpand(conversation.conversation_id)}
              className="w-full text-left flex justify-between items-center text-gray-300"
            >
              <span>{new Date(conversation.created_at + 'Z').toLocaleString()}</span>
              <span>{expandedConversations.includes(conversation.conversation_id) ? '-' : '+'}</span>
            </button>
            {expandedConversations.includes(conversation.conversation_id) && (
              <div className="mt-2">
                <div><strong>Last Modified:</strong> {new Date(conversation.last_modified + 'Z').toLocaleString()}</div>
                <div className="message-list mt-2 space-y-2">
                  {conversation.chat_history.map((msg, i) => (
                    <div key={i} className={`flex items-start ${msg.role === 'assistant' ? 'flex-row' : 'flex-row-reverse'}`}> 
                      {msg.role === 'assistant' && (
                        <img src={chatbotData.avatar} alt="Chatbot" className="w-8 h-8 rounded-full mr-2" />
                      )}
                      <div className={`mt-1 p-3 ${msg.role === 'assistant' ? 'bg-gray-800 text-white rounded-[20px_20px_20px_5px]' : 'bg-white text-gray-800 rounded-[20px_20px_5px_20px]'}`}>
                        {msg.content}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="text-red-400 hover:underline mt-2"
                  onClick={() => handleDeleteClick(conversation.conversation_id)}
                >
                  Delete Conversation
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-900 rounded-lg p-6 w-80 text-center">
            <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
            <p className="mb-4">Are you sure you want to delete this conversation? This action cannot be undone.</p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationHistory;
import React, { useState } from 'react';
import { PlusIcon, TrashIcon, PencilIcon, ChevronDownIcon, DocumentIcon, LinkIcon, ChatBubbleLeftIcon, CheckCircleIcon, ArrowPathIcon, CloudArrowUpIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import AddKnowledgeSourceModal from './AddKnowledgeSourceModal';

const KnowledgeBase = ({ fetchChatbotData, longTermDocuments, deleteDocument, chatbotId}) => {
  const [expandedDocIndex, setExpandedDocIndex] = useState(null);
  const [docContent, setDocContent] = useState('');
  const [error, setError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const toggleRow = async (index, chatbotId, docId) => {
    if (expandedDocIndex === index) {
      setExpandedDocIndex(null);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/getDataFromKnowledgeSource/${chatbotId}/${docId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Document content:', data.content);
      setDocContent(data.content);
      setExpandedDocIndex(index);
      setError('');
    } catch (error) {
      console.error('Error fetching document content:', error);
      setError('Failed to fetch document content. Please try again later.');
    }
  };

  const getSourceIcon = (sourceType) => {
    switch (sourceType) {
      case 'document':
        return <DocumentIcon className="h-8 w-8" />;
      case 'text_prompt':
        return <ChatBubbleLeftIcon className="h-8 w-8" />;
      case 'website':
        return <GlobeAltIcon className="h-8 w-8" />;
      default:
        return null;
    }
  };

  const handleDeleteClick = (index) => {
    setDocToDelete(index);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    deleteDocument(docToDelete);
    setShowDeleteModal(false);
    setDocToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setDocToDelete(null);
  };

  return (
<div className="mb-2"> 
       <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <button 
            onClick={() => fetchChatbotData()}
            className="text-white p-2">
            <ArrowPathIcon className="h-6 w-6" />
          </button>
          <button 
            onClick={() => setShowAddModal(true)}
            className="text-white p-2">
            <PlusIcon className="h-6 w-6" />
          </button>
        </div>
      </div>

      {/* Header Row */}
         <div className="grid grid-cols-12 bg-gray-900 border-b border-gray-600 sticky top-0 shadow-lg">
        <div className="col-span-10 p-3 font-semibold text-gray-100">Knowledge Sources</div>
      </div>
      
      {/* Data Rows */}
            
            {longTermDocuments.map((doc, index) => (
              <div key={index} className="group">
                <div className={`
                  grid grid-cols-12
                  ${index % 2 === 0 
                    ? 'bg-gray-900' 
                    : 'bg-gray-800'}
                  hover:bg-gray-750
                  border-b border-gray-700/50
                  transition-all duration-150 backdrop-blur-sm
                `}>
                  {/* Knowledge Source Cell */}
                  <div className="col-span-10 p-3 border-r border-gray-700/50 flex items-center space-x-3">
                    <div className="flex-shrink-0 text-blue-400">
                      {getSourceIcon(doc.source_type)}
                    </div>
                    <span className="text-gray-100 truncate font-medium">{doc.name}</span>
                  </div>
                  
                  {/* Actions Cell */}
                  <div className="col-span-2 p-3 flex justify-end space-x-2">
                    <button
                      onClick={() => toggleRow(index, doc.chatbot_id, doc.id)}
                      className={`p-1.5 rounded-md hover:bg-blue-500/20 hover:text-blue-400
                        transform transition-all duration-200 text-gray-400
                        hover:shadow-[0_0_8px_rgba(59,130,246,0.3)]
                        ${expandedDocIndex === index ? 'rotate-180 bg-blue-500/20 text-blue-400' : ''}`}
                    >
                      <ChevronDownIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>
            
                {/* Expanded Content */}
                {expandedDocIndex === index && (
                  <div className="bg-gray-800 p-6 border-b border-gray-700/50">
                    <div className="space-y-4 max-w-2xl mx-auto">
                      <div className="flex flex-col space-y-2">
                        <label className="text-sm text-gray-400">Knowledge Source</label>
                        <input
                          type="text"
                          value={doc.name}
                          className="bg-gray-900/50 border border-gray-700/50 rounded-md p-2 text-gray-200
                            focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all duration-150"
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="text-sm text-gray-400">Content</label>
                        <textarea
                          value={docContent}
                          className="bg-gray-900/50 border border-gray-700/50 rounded-md p-2 text-gray-200 h-32
                            focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50 transition-all duration-150"
                        />
                      </div>
                      <div className="flex justify-between">
                        <button
                          onClick={() => handleDeleteClick(index)}
                          className="p-2 rounded-md hover:bg-red-500/20 hover:text-red-400
                            transform transition-all duration-200 text-gray-400
                            hover:shadow-[0_0_8px_rgba(239,68,68,0.3)]"
                        >
                          <TrashIcon className="h-4 w-4" />
                        </button>
                        {/* <button
                          className="p-2 rounded-md hover:bg-blue-500/20 hover:text-blue-400
                            transform transition-all duration-200 text-gray-400
                            hover:shadow-[0_0_8px_rgba(59,130,246,0.3)]"
                        >
                          <CloudArrowUpIcon className="h-4 w-4" />
                        </button> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
      {showAddModal && (
        <div className="pt-4">
          <AddKnowledgeSourceModal fetchChatbotData={fetchChatbotData} chatbotId={chatbotId}/>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4 text-white">Confirm Delete</h3>
            <p className="mb-4 text-gray-300 text-md">Are you sure you want to delete this knowledge source?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={cancelDelete}
                className="px-4 py-2 bg-gray-600 text-gray-200 rounded-pill hover:bg-gray-500 text-sm"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-700 text-white rounded-pill hover:bg-red-800 text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgeBase;
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './styles/App.css';
import Navbar from './components/organisms/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './components/context/AuthContext';
import ChatbotSetupPage from './components/pages/ChatbotSetupPage';
import ProtectedRoute from './components/context/ProtectedRoute';
import ReturnPage from './components/pages/ReturnPage'; 
import AnnouncementBar from './components/molecules/AnnouncementBar';
import Chatbot from './components/organisms/Chatbot/Chatbot';
import PricingPage from './components/pages/PricingPage'
import Dashboard from './components/pages/DashboardPage';
import LandingPage from './components/pages/LandingPage'
import ChatbotDashboardTemplate from './components/templates/ChatbotDashboardTemplate';
import ContactPage from './components/pages/ContactPage';
import ContestPage from './components/pages/ContestPage';
import ChatbotShareable from './components/organisms/Chatbot/ChatbotShareable';
import Subscription from './components/organisms/Subscription';
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="*" element={
            <>
              <Navbar />
              <AnnouncementBar/>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/contest" element={<ContestPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                <Route path="/chatbot-setup" element={<ProtectedRoute element={<ChatbotSetupPage />} />} />
                <Route path="/edit-chatbot/:chatbotId" element={<ProtectedRoute element={<ChatbotDashboardTemplate />} />} />
                <Route path="/chatbot/:chatbotId" element={<ChatbotShareable />} />
                <Route path="/return" element={<ReturnPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </>
          } />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </AuthProvider>
  );
};

export default App;
import React from 'react';

const QuickReplies = ({ setInput, handleSend, darkMode, themeColor, quickReplies }) => {

  const extractColorFromGradient = (gradient) => {
    const match = gradient.match(/rgba?\(([^)]+)\)|#([0-9A-F]{6}|[0-9A-F]{3})/i);
    return match ? match[0] : 'black'; // Fallback to black if no valid color
  };

  const handleQuickReply = (reply) => {
    console.log(`reply long: ${JSON.stringify(reply)}`);
    setInput(reply.longMessage); // Set the input value to the quick reply
    handleSend(reply.longMessage); // Call handleSend to send the quick reply immediately
  };

  return (
    <div className={`p-2 ${darkMode ? 'bg-gray-800' : 'bg-transparent'} transition-all`}>
      <div className={`flex flex-col items-end space-y-2`}>
        {quickReplies.map((reply, index) => {
          const textColor = darkMode ? 'white' : 
            /^linear-gradient/.test(themeColor) 
              ? extractColorFromGradient(themeColor) // Extract color from gradient
              : themeColor || 'black'; // Fallback to black if no valid color

          return (
            <button
              key={index}
              onClick={() => handleQuickReply(reply)}
              className={`relative px-4 py-2 text-xs font-medium rounded-full focus:outline-none transition-all duration-200 ease-in-out`} 
              style={{
                color: textColor, // Use extracted color or themeColor for text color
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgray'} // Optional hover effect
              onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
            >
              {reply.shortReply} {/* Use shortReply for button text */}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default QuickReplies;
import React from "react";
import { useParams } from "react-router-dom";
import Chatbot from "./Chatbot";

const ChatbotShareable = () => {
    const { chatbotId } = useParams();

    return (
        <div className="mt-4 h-[450px] w-[350px] bg-transparent rounded-lg shadow-lg overflow-hidden mx-auto relative">
            <Chatbot chatbotId={chatbotId} isClosed={false} />
        </div>
    );
}

export default ChatbotShareable;
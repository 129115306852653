import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContestEntryForm from '../molecules/ContestEntryForm';
import Chatbot from '../organisms/Chatbot/Chatbot';

function ContestPage() {
    const [showForm, setShowForm] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setShowForm(!showForm);
    };

    return (
        <div className="">
            <header className='relative items-center justify-center bg-gray-900 text-white min-h-screen transition-colors duration-500 px-1 py-8'>
                <div className='py-2'>
                    <h1 className="text-5xl text-center font-bold bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-transparent bg-clip-text margin-auto">
                        Unleash Your Creativity and Win a New Mac Desktop Mini!
                    </h1>
                    <div className="bg-gray-800 mt-4 rounded-lg p-2 shadow-lg">
                        <h2 className="text-3xl font-bold text-center mb-6">🎉 Announcing the Smarter Chatbots Challenge!</h2>
                        <p className="text-lg text-center max-w-3xl mx-auto">
                            We're thrilled to invite you to our exciting competition, where you can unleash your creativity and potentially win a new Mac Desktop Mini! We've built Smarter Chatbots to make generative AI assistants accessible to everyone, and now we want to see what amazing assistants you can create.
                        </p>
                        <img 
                            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Purple%20and%20Red%20Did%20You%20Know%20Fact%20LinkedIn%20Post.png" 
                            alt="Contest Image" 
                            className="mt-4 mx-auto max-w-xs max-h-xs rounded"
                        />
                        <p className="mt-4 text-lg text-center max-w-3xl mx-auto font-bold bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-transparent bg-clip-text">
                            Submission Deadline is November 30th at 11:59PM PDT
                        </p>
                        <div className="flex justify-center mt-4">
                            <div className="w-full">
                                <button 
                                    className='button px-6 py-2 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold shadow-lg hover:from-blue-700 hover:to-purple-800 transition-all duration-300 flex items-center justify-center w-full'
                                    onClick={toggleDropdown}
                                >
                                    👉 Submit Entry Here
                                    <span className={`ml-2 transform transition-transform ${showForm ? 'rotate-180' : 'rotate-0'}`}>
                                        ▼
                                    </span>
                                </button>
                                {showForm && (
                                    <div className="mt-2 w-full bg-white shadow-lg rounded-lg">
                                        <ContestEntryForm />
                                    </div>
                                )}
                            </div>
                        </div>
                     
                    </div>
                </div>
            </header>
            <main className="mx-auto text-center bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white pt-6 p-1">
            
               
                <section className="mb-12 py-10 bg-gray-800 rounded-lg shadow-lg">
                    <h2 className="text-3xl font-bold mb-4 text-center">📜 Competition Rules</h2>
                    <div className="max-w-4xl mx-auto">
                        <p className="text-lg">
                            To participate in the competition, all entries must be submitted before November 30th at 11:59 PM PDT. All you have to do is create an assistant, click deploy, and copy and paste the URL into the contest entry form.
                        </p>
                        <p className="text-lg mt-6">
                            Our panel of judges, consisting of industry leaders, will review each idea and provide feedback. They will decide the winner based on creativity and impact. While there is only one grand winner, we might decide to give out additional prizes.
                        </p>
                        <p className="text-lg mt-6">
                            For the duration of this contest, the free trial when you create an account will give you full access until the end of the year to play around with the platform. You can submit a maximum of one entry into this contest.
                        </p>
                        <p className="text-lg mt-6">
                            Everyone is welcome to join, and we have the computer ready to ship out to the winner in time for Christmas.
                        </p>
                    </div>
                    <button
                        onClick={() => navigate("/dashboard")}
                        className="mt-6 bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-2 px-4 rounded-full"
                    >
                        👉 Get Started Now
                    </button>
                </section>
                                
                <section className="mb-12 py-10 bg-gray-800 rounded-lg shadow-lg">
                    <h2 className="text-3xl font-bold mb-4 text-center">🌟 Our Story</h2>
                    <div className="max-w-4xl mx-auto">
                        <p className="text-lg">
                            We met incredible people who wanted their own AI assistants for unique use cases. But creating these assistants required AI engineers and involved time-consuming, repetitive tasks. We believed everyone should have access to this technology, so we built a <strong>no-code AI assistant creator platform</strong>. Now, anyone can turn their ideas into reality, share them with friends, or integrate them into their business—no coding required! But we provide a <strong>REST API too</strong> in case you want to get even more hands-on.
                        </p>
                        <p className="text-lg mt-6 text-left font-bold">
                            Now, we want to see what ideas you have! Whether it's a:
                        </p>
                        <ul className="list-disc list-inside mt-4 text-lg space-y-2 text-left">
                            <li>📚 <strong>Educational Bot</strong> that makes learning fun</li>
                            <li>🥘 <strong>Restaurant Menu Bot</strong> that enhances dining experiences</li>
                            <li>🏠 <strong>Real Estate Assistant</strong> that connects buyers with their dream homes</li>
                            <li>✨ <strong>Anything you can imagine!</strong></li>
                        </ul>
                    </div>
                </section>
            </main>
            <div id="chatbot-container" className="fixed bottom-5 right-10 bg-transparent rounded-lg overflow-hidden flex justify-center items-center">
            <div className="w-full md:w-[300px] bg-transparent rounded-lg shadow-lg overflow-hidden mx-auto relative">
              <Chatbot chatbotId={"90ebe547-7350-4b48-b9bc-2fc39b5b2535"} />
            </div>
          </div>
        </div>
    );
}

export default ContestPage;
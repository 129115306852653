import React, { useEffect } from 'react';
import './ContactPage.css';

const ContactPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '46387830',
          formId: '6a1a4881-8ed7-47ab-bed5-f4578b9e564b',
          target: '#hubspotForm'
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div className="contact-page bg-gray-900 text-white min-h-screen flex items-center justify-center" id="contact">
      <div className="container mx-auto p-8">
        <h2 className="text-4xl font-bold mb-8 text-center">Let's Chat!</h2>
        <div id="hubspotForm" className="bg-gray-800 p-8 rounded-lg shadow-lg text-white"></div>
      </div>
    </div>
  );
};

export default ContactPage;
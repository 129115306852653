import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { FaCopy } from 'react-icons/fa';
import { MdLink, MdQrCode, MdApi, MdCode } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { fetchSubscriptionStatus } from '../../services/api';

const IntegrationTab = ({ chatbot }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('qr');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const chatbotId = chatbot.chatbot_id;
  const deploymentLink = `https://smarterchatbots.com/chatbot/${chatbotId}`;
  const apiEndpoint = `${process.env.REACT_APP_REST_API}/chatbots/public/chatbot/chat`;

  const apiPayload = `
{
  "user_input": "Your message here",
  "conversation_id": "",
  "message_history": [],
  "chatbot_id": "${chatbotId}"
}`;

  const apiResponse = `
{
  "chat_history": [],
  "conversation_id": ""
}`;

  const curlCommand = `
curl -X POST ${apiEndpoint} \\
-H "Content-Type: application/json" \\
-d '${apiPayload.trim()}'`;

  const header_tag = `
  <style>
        #chatbot-container {
            position: fixed;;
            bottom: 20px;
            right: 20px;
            z-index: 1000; 
            max-width: 300px;
            max-height: 400px;
            color: white;
        }
    </style>`;

  const body_tag = `
    <div id="chatbot-container">
        <script src="https://main.dtepo6uwwnfyu.amplifyapp.com/chatbot.bundle.js"></script>
        <script>
            window.Chatbot.init({ chatbotId: '${chatbotId}' });
        </script>
    </div>`;

  const html_file = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>AI Chatbot Integration - Simple as 1-2-3</title>
    <style>
        /* Modern minimal styles */
        :root {
            --gradient: linear-gradient(90deg, #00c4cc, #7c2ae8);
        }
        
        body {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
            background: #0f172a;
            color: #fff;
            line-height: 1.6;
        }

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 4rem 2rem;
        }

        .hero {
            text-align: center;
            padding: 6rem 2rem;
            background: #1e293b;
            border-radius: 0 0 3rem 3rem;
        }

        h1 {
            font-size: 3.5rem;
            margin-bottom: 1.5rem;
            background: var(--gradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .steps {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 2rem;
            margin: 4rem 0;
        }

        .step {
            background: #1e293b;
            padding: 2rem;
            border-radius: 1rem;
            transition: transform 0.3s;
        }

        .step:hover {
            transform: translateY(-5px);
        }

        .code-block {
            background: #0f172a;
            padding: 1.5rem;
            border-radius: 0.5rem;
            font-family: monospace;
            margin: 1rem 0;
            overflow-x: auto;
        }

        .gradient-btn {
            background: var(--gradient);
            color: white;
            border: none;
            padding: 1rem 2rem;
            border-radius: 2rem;
            font-size: 1.1rem;
            cursor: pointer;
            transition: opacity 0.3s;
        }

        .gradient-btn:hover {
            opacity: 0.9;
        }

        /* Demo chatbot container */
        #chatbot-container {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 1000;
            max-width: 300px;
            max-height: 400px;
            padding: 4px;
        }
    </style>
</head>
<body>
    <div class="hero">
        <h1>Integrate AI Chat in Seconds</h1>
        <p>Add powerful conversational AI to your website with just two lines of code</p>
    </div>
    <div style="text-align: center; margin-top: 4rem;">
        <h2>See It In Action</h2>
        <p>Your assistant is running live in the bottom-right corner of this page!</p>
    </div>

    <div class="container">
        <div class="steps">
            <div class="step">
                <h3>1. Add the Script</h3>
                <div class="code-block">
                    &lt;script src="https://main.dtepo6uwwnfyu.amplifyapp.com/chatbot.bundle.js"&gt;&lt;/script&gt;
                </div>
            </div>

            <div class="step">
                <h3>2. Initialize the Chatbot</h3>
                <div class="code-block">
                    &lt;script&gt;
                        window.Chatbot.init({ 
                            chatbotId:'${chatbotId}'
                        });
                    &lt;/script&gt;
                </div>
            </div>

            <div class="step">
                <h3>3. Style the Container</h3>
                <div class="code-block">
                    #chatbot-container {
                        position: fixed;
                        bottom: 20px;
                        right: 20px;
                        z-index: 1000;
                    }
                </div>
            </div>
        </div>

       
    </div>

    <!-- Demo chatbot implementation -->
    <div id="chatbot-container">
        <script src="https://main.dtepo6uwwnfyu.amplifyapp.com/chatbot.bundle.js"></script>
        <script>
            window.Chatbot.init({ chatbotId: '${chatbotId}' });
        </script>
    </div>
</body>
</html>
  `;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text.trim());
    alert("Code copied to clipboard!");
  };

  useEffect(() => {
    const setSubscriptionStatus = async () => {
      try {
        if (isAuthenticated && user) {
          await fetchSubscriptionStatus(user.sub);
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    setSubscriptionStatus();
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <p className="text-center py-4 text-gray-300">Loading...</p>;
  }

  const TabButton = ({ id, icon, label }) => (
    <button
      onClick={() => setActiveTab(id)}
      className={`flex items-center px-4 py-2 rounded-t-lg ${
        activeTab === id ? 'bg-gray-800 text-blue-400' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
      }`}
    >
      {icon}
      <span className="ml-2">{label}</span>
    </button>
  );

  const DropdownButton = ({ id, icon, label }) => (
    <button
      onClick={() => {
        setActiveTab(id);
        setIsDropdownOpen(false);
      }}
      className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-600 w-full"
    >
      {icon}
      <span className="ml-2">{label}</span>
    </button>
  );

  return (
    <div className="integration-tab p-6 bg-gray-900 text-gray-300">
      <h2 className="text-2xl font-bold mb-6 text-gray-100">Integrations</h2>

      {/* Tabs for Desktop */}
      <div className="hidden md:flex mb-4 space-x-2">
        <TabButton id="qr" icon={<MdQrCode />} label="QR Code" />
        <TabButton id="website" icon={<MdCode />} label="Website" />
        <TabButton id="url" icon={<MdLink />} label="Public URL" />
        <TabButton id="api" icon={<MdApi />} label="REST API" />
      </div>

      {/* Dropdown for Mobile */}
      <div className="md:hidden mb-4">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="bg-gray-700 text-gray-300 w-full px-4 py-2 rounded-lg flex justify-between items-center"
        >
          {activeTab === 'qr' && 'QR Code'}
          {activeTab === 'website' && 'Website'}
          {activeTab === 'url' && 'Public URL'}
          {activeTab === 'api' && 'REST API'}
          <span className="ml-2">{isDropdownOpen ? '▲' : '▼'}</span>
        </button>
        {isDropdownOpen && (
          <div className="bg-gray-800 mt-2 rounded-lg">
            <DropdownButton id="qr" icon={<MdQrCode />} label="QR Code" />
            <DropdownButton id="website" icon={<MdCode />} label="Website" />
            <DropdownButton id="url" icon={<MdLink />} label="Public URL" />
            <DropdownButton id="api" icon={<MdApi />} label="REST API" />
          </div>
        )}
      </div>

      <div className="bg-gray-800 p-4 rounded-lg">
        {activeTab === 'qr' && (
          <div>
            <h3 className="text-xl font-semibold mb-4 text-gray-200">QR Code</h3>
            <p className="mb-4">Scan the QR code to share your chatbot:</p>
            <QRCodeSVG value={deploymentLink} size={200} />
          </div>
        )}

        {activeTab === 'website' && (
          <div>
            <h3 className="text-xl font-semibold mb-4 text-gray-200">Website Javascript Widget</h3>
            <p className="mb-2"><strong>Insert this style snippet in your header.</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{header_tag}</pre>
            <button
              onClick={() => copyToClipboard(header_tag)}
              className="bg-gray-700 px-3 py-1 rounded-pill text-sm hover:bg-blue-700 transition"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>

            <div className='mt-4'>
            <p className="mb-2"><strong>Insert the widget inside your body tag at the bottom.</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{body_tag}</pre>
            <button
              onClick={() => copyToClipboard(body_tag)}
              className="bg-gray-700 px-3 py-1 rounded-pill text-sm hover:bg-blue-700 transition"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>
          </div>

          <h4 className="text-xl font-semibold my-4 text-gray-200">Example HTML File:</h4>

          <div className='mt-4'>
            <p className="mb-2"><strong>Here is a full HTML code snippet to test</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{html_file}</pre>
            <button
              onClick={() => copyToClipboard(html_file)}
              className="bg-gray-700 px-3 py-1 rounded-pill text-sm hover:bg-blue-700 transition"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>
          </div>

          </div>
          
        )}

        {activeTab === 'url' && (
          <div>
            <h3 className="text-xl font-semibold mb-4 text-gray-200">Public URL</h3>
            <p className="mb-2">Use the following link to access your deployed chatbot:</p>
            <a href={deploymentLink} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline break-all">
              {deploymentLink}
            </a>
          </div>
        )}

        {activeTab === 'api' && (
          <div>
            <h3 className="text-xl font-semibold mb-4 text-gray-200">REST API</h3>
            <p className="mb-2"><strong>API Endpoint:</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{apiEndpoint}</pre>
            <button
              onClick={() => copyToClipboard(apiEndpoint)}
              className="bg-blue-600 px-3 py-1 rounded text-sm hover:bg-blue-700 transition mb-4"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>
            
            <p className="mt-4 mb-2"><strong>Request Payload:</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{apiPayload}</pre>
            <button
              onClick={() => copyToClipboard(apiPayload)}
              className="bg-blue-600 px-3 py-1 rounded text-sm hover:bg-blue-700 transition mb-4"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>

            <p className="mt-4 mb-2"><strong>Example Response:</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{apiResponse}</pre>
            <button
              onClick={() => copyToClipboard(apiResponse)}
              className="bg-blue-600 px-3 py-1 rounded text-sm hover:bg-blue-700 transition mb-4"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>

            <p className="mt-4 mb-2"><strong>Curl Command:</strong></p>
            <pre className="bg-gray-700 p-2 rounded mb-2 text-gray-300">{curlCommand}</pre>
            <button
              onClick={() => copyToClipboard(curlCommand)}
              className="bg-blue-600 px-3 py-1 rounded text-sm hover:bg-blue-700 transition"
            >
              <FaCopy className="inline mr-1" /> Copy
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationTab;
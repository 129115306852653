import React, { useState, useEffect } from 'react';
import { PlusIcon, TrashIcon, PencilIcon, CheckIcon, CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import AddKnowledgeSourceModal from './AddKnowledgeSourceModal';
import { useAuth } from '../context/AuthContext';
import Chatbot from './Chatbot/Chatbot';
import KnowledgeBase from './KnowledgeBase';

const ChatbotTraining = ({ chatbotData, setChatbotData, fetchChatbotData }) => {
  const [shortTermPrompts, setShortTermPrompts] = useState([]);
  const [longTermDocuments, setLongTermDocuments] = useState([]);
  const [greetingMessage, setGreetingMessage] = useState('');
  const [quickReplies, setQuickReplies] = useState(Array(3).fill({ shortReply: '', longMessage: '', order: 0 }));
  const [newPrompt, setNewPrompt] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [promptSuccessMessage, setPromptSuccessMessage] = useState('');
  const [editingPromptIndex, setEditingPromptIndex] = useState(null);
  const { getAccessToken } = useAuth();

  const [isEditingResponseStyle, setIsEditingResponseStyle] = useState(false);
  const [isEditingGreetingMessage, setIsEditingGreetingMessage] = useState(false);
  const [isEditingKnowledgeBase, setIsEditingKnowledgeBase] = useState(false);

  const [rerenderChatbot, setRerenderChatbot] = useState(false); // State to trigger rerender

  // New state variables for input tracking
  const [textPrompt, setTextPrompt] = useState('');
  const [documentInput, setDocumentInput] = useState('');
  const [websiteInput, setWebsiteInput] = useState('');

  const [openSection, setOpenSection] = useState(null); // State to manage accordion sections

  useEffect(() => {
    if (chatbotData && chatbotData.chatbot_id) {
      setGreetingMessage(chatbotData.initial_message || '');
      setQuickReplies(chatbotData.quick_replies?.length === 3 ? chatbotData.quick_replies : Array(3).fill({ shortReply: '', longMessage: '', order: 0 }));
      fetchMemorySources();
    }
  }, [chatbotData]);

  const fetchMemorySources = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/fetchAllChatbotKnowledgeSources`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chatbot_id: chatbotData.chatbot_id }),
      });
      if (!response.ok) throw new Error('Failed to fetch knowledge sources.');
      const data = await response.json();
      setShortTermPrompts(chatbotData.short_term_memory || []);
      setLongTermDocuments(data.knowledge_sources);
    } catch (error) {
      console.log('Please knowledge base: ' + error.message);
    }
  };

  const updateChatbotData = async (data) => {
    try {
      const accessToken = await getAccessToken();
      await fetch(`${process.env.REACT_APP_REST_API}/chatbots/${chatbotData.chatbot_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });
      setChatbotData(prev => ({ ...prev, ...data }));
      setRerenderChatbot(prev => !prev); // Trigger rerender
      return true;
    } catch (error) {
      setErrorMessage('Error updating chatbot: ' + error.message);
      return false;
    }
  };

  const addPrompt = () => {
    if (!newPrompt.trim()) return;
    const updatedPrompts = [...shortTermPrompts, newPrompt.trim()];
    setShortTermPrompts(updatedPrompts);
    updateChatbotData({ short_term_memory: updatedPrompts });
    setNewPrompt('');
  };

  const editPrompt = (index, value) => {
    const updatedPrompts = shortTermPrompts.map((prompt, i) => i === index ? value : prompt);
    setShortTermPrompts(updatedPrompts);
  };

  const savePrompt = async (index) => {
    const success = await updateChatbotData({ short_term_memory: shortTermPrompts });
    if (success) {
      setPromptSuccessMessage('Prompt saved successfully.');
    } else {
      setPromptSuccessMessage('Failed to save prompt.');
    }
    setTimeout(() => setPromptSuccessMessage(''), 3000); // Clear message after 3 seconds
    setEditingPromptIndex(null); // Exit editing mode
  };

  const deletePrompt = (index) => {
    const updatedPrompts = shortTermPrompts.filter((_, i) => i !== index);
    setShortTermPrompts(updatedPrompts);
    updateChatbotData({ short_term_memory: updatedPrompts });
  };

  const saveGreeting = async () => {
    const success = await updateChatbotData({
      initial_message: greetingMessage,
      quick_replies: quickReplies
    });
    if (success) {
      setSuccessMessage('Greeting message saved successfully.');
      setErrorMessage('');
    } else {
      setSuccessMessage('');
      setErrorMessage('Failed to save greeting message.');
    }
  };

  const handleQuickReplyChange = (index, field, value) => {
    const updatedReplies = quickReplies.map((reply, i) => i === index ? { ...reply, [field]: value } : reply);
    setQuickReplies(updatedReplies);
  };

  const addLongTermDocument = (document) => {
    const updatedDocuments = [...longTermDocuments, document];
    setLongTermDocuments(updatedDocuments);
    updateChatbotData({ long_term_memory: updatedDocuments });
  };

  const deleteDocument = async (index) => {
    const id = longTermDocuments[index].id;
    try {
      const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/deleteKnowledgeSource/${chatbotData.chatbot_id}/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete document');
      const updatedDocuments = longTermDocuments.filter((_, i) => i !== index);
      setLongTermDocuments(updatedDocuments);
    } catch (error) {
      setErrorMessage('Failed to delete document: ' + error.message);
    }
  };

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="min-h-screen sm:p-6 w-full">
      <div className="bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] p-3 sm:p-4 rounded-lg shadow-lg w-full mb-2">
        <p className="text-white text-sm font-semibold">
          💡 Need help brainstorming? Click the chat icon to connect with our setup assistant!
        </p>
      </div>
      <div className='hero-banner bg-gradient-to-r from-gray-800 to-gray-900 p-2 sm:p-0 rounded-lg mb-6 shadow-lg relative'>
        <h1 className="text-4xl font-bold text-white p-2">Train Your AI Assistant </h1>
        <p className="text-gray-300 p-2 "> Only 3 Simple Steps</p>
        
        <div className="space-y-6">
          <div className="bg-gray-700/50 p-2 rounded-lg">
            <h2 onClick={() => toggleSection(1)} className="text-2xl font-semibold text-blue-300 mb-3 mt-3 cursor-pointer flex justify-between items-center">
              1. Set Up the Welcome Message 👋
              <ChevronDownIcon className={`h-5 w-5 transform transition-transform duration-300 ${openSection === 1 ? 'rotate-180' : 'rotate-0'}`} />
            </h2>
            {openSection === 1 && (
              <>
                <p className="text-gray-200">Create a warm greeting and quick reply options to help your customers start the conversation naturally.</p>
                <div className="mt-4 bg-gray-600/50 p-2 sm:p-4 rounded-lg">
                  <p className="text-sm text-gray-300 font-medium mb-2">Example Messages:</p>
                  <div className="space-y-2">
                    <div className="bg-blue-500/20 p-3 rounded-lg text-gray-200">"Hi there! 👋 How can I help you today?"</div>
                    <div className="bg-blue-500/20 p-3 rounded-lg text-gray-200">"Welcome! Would you like to: 
                      • Learn about our products
                      • Get support
                      • Ask question about"</div>
                  </div>
                  <div className="p-2 mb-2">
                    <div className="flex justify-between items-center mt-4">
                      <button onClick={saveGreeting} className="ml-auto text-sm hover:text-gray-500 flex items-center space-x-1">
                        <CheckCircleIcon className="h-4 w-4" />
                        <span>Save</span>
                      </button>
                    </div>
                    {successMessage && (
                      <div className="text-white w-full text-right mb-4">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="mt-2 p-2 text-white rounded">
                        {errorMessage}
                      </div>
                    )}
                    <label className="block text-gray-300 text-sm font-medium mb-2">Greeting Message</label>
                    <textarea
                      value={greetingMessage}
                      onChange={(e) => setGreetingMessage(e.target.value)}
                      className="w-full p-2 rounded text-gray-800 bg-white border border-gray-700"
                      rows="3"
                      placeholder="Enter your agent's greeting message"
                    />
                    <button onClick={() => setIsEditingGreetingMessage(!isEditingGreetingMessage)} className="p-1 text-white rounded flex mt-4">
                      <span className="mr-2">Edit Quick Replies</span>
                      <span className={`transform transition-transform duration-300 ${isEditingGreetingMessage ? 'rotate-180' : 'rotate-0'}`}>
                        <ChevronDownIcon className="h-5 w-5" />
                      </span>
                    </button>
                    {isEditingGreetingMessage && (
                      <div className="mb-4 mt-4 text-gray-500">
                        {quickReplies.map((reply, index) => (
                          <div key={index} className="flex mb-2 items-center">
                            <input
                              value={reply.shortReply}
                              onChange={(e) => handleQuickReplyChange(index, 'shortReply', e.target.value)}
                              className="w-1/2 p-2   rounded mr-2 text-gray-200"
                              placeholder={`Short reply ${index + 1}`}
                            />
                            <textarea
                              value={reply.longMessage}
                              onChange={(e) => handleQuickReplyChange(index, 'longMessage', e.target.value)}
                              className="w-1/2 p-2 rounded text-gray-200"
                              rows="1"
                              placeholder={`Long message ${index + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="bg-gray-700/50 p-2 rounded-lg">
            <h2 onClick={() => toggleSection(2)} className="text-2xl font-semibold text-blue-300 mb-3 cursor-pointer flex justify-between items-center">
              2. Create Your Assistant's Persona 🎭
              <ChevronDownIcon className={`h-5 w-5 transform transition-transform duration-300 ${openSection === 2 ? 'rotate-180' : 'rotate-0'}`} />
            </h2>
            {openSection === 2 && (
              <>
                <p className="text-gray-200">Define how your assistant interacts - their tone, language style, and personality traits.</p>
                <div className="mt-4 bg-gray-600/50 p-3 sm:p-4 rounded-lg">
                  <p className="text-sm text-gray-300 font-medium mb-2">Persona Examples:</p>
                  <div className="space-y-2 mb-2">
                    <div className="bg-blue-500/20 p-3 rounded-lg text-gray-200">• Friendly & Casual: Uses emojis, short sentences, casual language</div>
                    <div className="bg-blue-500/20 p-3 rounded-lg text-gray-200">• Professional Expert: Formal tone, detailed responses, industry terminology</div>
                  </div>
                  <div className="mb-2">
                    <div className="flex justify-between items-center">
                    </div>
                    <label className="block text-gray-300 text-sm font-medium mt-4">Response Styles</label>
                    {shortTermPrompts.map((prompt, index) => (
                      <div key={index} className="rounded flex justify-between items-center m-1 bg-white p-2">
                        <input
                          value={prompt}
                          onChange={(e) => editPrompt(index, e.target.value)}
                          className="flex-grow  py-1 rounded text-gray-800"
                          disabled={editingPromptIndex !== index}
                        />
                        <div className="flex items-center">
                          {editingPromptIndex === index ? (
                            <>
                              <button onClick={() => savePrompt(index)} className="p-1 rounded mx-1 text-gray-800 hover:bg-gray-700">
                                <CheckIcon className="h-4 w-4" />
                              </button>
                              <button onClick={() => deletePrompt(index)} className="p-1 rounded mx-1 text-gray-800 hover:bg-gray-700">
                                <TrashIcon className="h-4 w-4" />
                              </button>
                            </>
                          ) : (
                            <button onClick={() => setEditingPromptIndex(index)} className="p-1 rounded mx-1 text-gray-700">
                              <PencilIcon className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    {promptSuccessMessage && (
                      <div className="text-white w-full text-right mb-4">
                        {promptSuccessMessage}
                      </div>
                    )}
                    <label className="block text-gray-300 text-sm font-medium">Add New Response Style</label>
                    <div className="mt-2 flex m-1">
                      <input
                        value={newPrompt}
                        onChange={(e) => setNewPrompt(e.target.value)}
                        placeholder="Enter Style"
                        className="mr-2 flex-grow bg-white text-gray-800 px-3 rounded p-1"
                      />
                      <button onClick={addPrompt} className="px-2 py-1 rounded-pill flex items-center border border-gray-100 text-gray-100 hover:bg-gray-700">
                        <PlusIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="bg-gray-700/50 p-2 rounded-lg">
            <h2 onClick={() => toggleSection(3)} className="text-2xl font-semibold text-blue-300 mb-3 cursor-pointer flex justify-between items-center">
              3. Add Knowledge Sources 📚
              <ChevronDownIcon className={`h-5 w-5 transform transition-transform duration-300 ${openSection === 3 ? 'rotate-180' : 'rotate-0'}`} />
            </h2>
            {openSection === 3 && (
              <>
                <p className="text-gray-200">Power your assistant with your business knowledge by uploading documents or connecting your website.</p>
                <KnowledgeBase 
                  fetchChatbotData={fetchChatbotData} 
                  longTermDocuments={longTermDocuments} 
                  deleteDocument={deleteDocument} 
                  chatbotId={chatbotData.chatbot_id}
                  textPrompt={textPrompt}
                  documentInput={documentInput}
                  websiteInput={websiteInput}
                  setTextPrompt={setTextPrompt}
                  setDocumentInput={setDocumentInput}
                  setWebsiteInput={setWebsiteInput}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className='w-full flex flex-wrap'>
        <div className="w-full mb-4">
          <div className="p-0 sm:p-6 rounded-lg">
            {/* Additional content */}
          </div>
        </div>
        <div className="w-full lg:w-1/2 bg-transparent">
          <div id="chatbot-container" className="fixed bottom-5 right-10 bg-transparent rounded-lg overflow-hidden flex justify-center items-center">
            <div className="w-full md:w-[300px] bg-transparent rounded-lg shadow-lg overflow-hidden mx-auto relative">
              <Chatbot chatbotId={"03b8c4e1-8fb1-41b1-9300-bda4c558514e"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotTraining;
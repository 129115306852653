import React, { useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Menu } from '@headlessui/react'; // Import Menu from headlessui
import { UserIcon } from '@heroicons/react/24/outline'; // Import UserIcon

const Navbar = () => {
  const { isAuthenticated, signOut, signIn } = useAuth(); // Destructure auth methods
  const navigate = useNavigate(); // Initialize useNavigate

  const navigation = [
    { name: 'Dashboard', href: '/dashboard', current: false }, // Update href
    { name: 'Pricing', href: '/pricing', current: false }, // Update href
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleSignIn = () => {
    signIn(); // Call signIn method
  };

  const handleLogout = () => {
    signOut(); // Call signOut method
  };

  return (
    <Disclosure as="nav" className="bg-gray-800">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-12 items-center justify-between"> {/* Reduced height for smaller navbar */}
          {/* Logo on the far left */}
          <div className="flex items-center cursor-pointer" onClick={() => navigate('/')}> {/* Make logo clickable */}
            <img
              alt="Your Company"
              src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/assets/SmarterChatbotsLogo.svg"
              className="h-8 w-auto"
            />
          </div>
          {/* Right section: Hamburger menu and navigation items */}
          <div className="flex items-center justify-end flex-1">
            {/* Hamburger menu for mobile view */}
            <div className="flex sm:hidden">
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
              </DisclosureButton>
            </div>
            {/* Desktop navigation items */}
            <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
              {isAuthenticated ? (
                <>  
                  <button
                    onClick={() => navigate('/dashboard')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Dashboard
                  </button>
                  <button
                    onClick={() => navigate('/pricing')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Pricing
                  </button>
                  <button
                    onClick={() => navigate('/contact')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Join Team
                  </button>
                  <button
                    onClick={() => navigate('/contest')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Contest
                  </button>
                  <button
                    onClick={() => navigate('/contact')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Report Bug
                  </button>
                  <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center text-gray-300 hover:bg-gray-700 hover:text-white rounded-full p-2">
                      <UserIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => navigate('/subscription')}
                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                          >
                            Manage Subscription
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleLogout}
                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                          >
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </>
              ) : (
                <>
                  <button
                    onClick={() => navigate('/contact')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Contact
                  </button>
                  <button
                    onClick={() => navigate('/pricing')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Pricing
                  </button>
                  <button
                    onClick={() => navigate('/contest')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Contest
                  </button>
                    <button
                    onClick={() => navigate('/contact')}
                    className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium')}
                  >
                    Join Team
                  </button>
                  <button onClick={handleSignIn} className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                    Sign in
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu items */}
      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          <DisclosureButton
                as="button"
                onClick={() => navigate('/pricing')}
                className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
              >
                Pricing
              </DisclosureButton>
          {isAuthenticated ? (
            <>
              <DisclosureButton
                as="button"
                onClick={() => navigate('/dashboard')}
                className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
              >
                Dashboard
              </DisclosureButton>
              <DisclosureButton
                as="button"
                onClick={() => navigate('/contact')}
                className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
              >
                Report Bug
              </DisclosureButton>
              <DisclosureButton
                as="button"
                onClick={() => navigate('/subscription')}
                className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
              >
                Manage Subscription
              </DisclosureButton>
          
              <DisclosureButton
                as="button"
                onClick={handleLogout}
                className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
              >
                Logout
              </DisclosureButton>
            </>
          ) : (
            <>
              {/* <DisclosureButton
                as="button"
                onClick={() => navigate('/pricing')}
                className={classNames('text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium')}
              >
                Pricing
              </DisclosureButton> */}
              <DisclosureButton
                as="button"
                onClick={handleSignIn}
                className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
              >
                Sign in
              </DisclosureButton>
            </>
          )}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}

export default Navbar;
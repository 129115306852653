import React from 'react';

const MessageInput = ({ input, setInput, handleSend, darkMode, themeColor, loading }) => {
  const extractColorsFromGradient = (gradient) => {
    const matches = gradient.match(/#([0-9A-F]{6}|[0-9A-F]{3})/gi);
    return matches ? matches : ['black', 'black']; // Fallback to black if no valid color
  };

  const isGradient = /^linear-gradient/.test(themeColor); 
  const [color1, color2] = isGradient ? extractColorsFromGradient(themeColor) : [themeColor, themeColor];

  return (
       <div className={`flex items-center p-2 border-t ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>  
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        placeholder="Type a message..."
        className={`flex-grow p-2 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} rounded-full border ${darkMode ? 'border-gray-600' : 'border-gray-300'} focus:outline-none`}
      />
      {loading ? (  
        <div className={`ml-2 w-6 h-6 border-4 border-t-transparent rounded-full animate-spin ${darkMode ? 'border-gray-200' : 'border-gray-500'}`}></div>
      ) : (
        <button onClick={() => handleSend(input)} className={`ml-2 p-2 rounded-full ${darkMode ? 'text-gray-200' : 'text-white'} focus:outline-none`}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 transform rotate-90" fill="none" stroke={isGradient ? 'url(#send-gradient)' : color1} viewBox="0 0 24 24">
          {isGradient && (
            <defs>
              <linearGradient id="send-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor={color1} />
                <stop offset="100%" stopColor={color2} />
              </linearGradient>
            </defs>
          )}
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
        </svg>
      </button>
      )}
    </div>
  );
};

export default MessageInput;
import React, { useState } from 'react';

const IndustrySection = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('ecommerce');

  const industries = [
    { id: 'ecommerce', label: 'Retail & E-commerce' },
    { id: 'restaurants', label: 'Restaurants' },
    { id: 'tourism', label: 'Tourism & Hospitality' },
    { id: 'authors', label: 'Authors & Creatives' },
    { id: 'fitness', label: 'Fitness & Personal Training' },
    { id: 'realestate', label: 'Real Estate' } // Added Real Estate
  ];

  const content = {
    ecommerce: {
      hero: "As an e-commerce business owner, you strive to provide the best shopping experience.",
      problem: "But rising customer expectations and fierce competition make it challenging to stand out and succeed.",
      empathy: "We understand the frustration of losing sales due to abandoned carts, and the struggle to provide 24/7 personalized support.",
      guide: "AI technology has emerged as a powerful ally for e-commerce businesses.",
      plan: [
        "Implement 24/7 automated customer support",
        "Offer personalized product recommendations",
        "Streamline order tracking and returns",
        "Enhance product discovery"
      ],
      imageSrc: "https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/pexels-olly-3807737.jpg",
      imageAlt: "E-commerce owner looking stressed at computer"
    },
    restaurants: {
      hero: "As a restaurant owner, you're passionate about creating memorable dining experiences.",
      problem: "However, managing reservations, handling rush hours, and maintaining consistent service can be overwhelming.",
      empathy: "We know how challenging it is to juggle multiple tasks while trying to keep every customer satisfied.",
      guide: "AI solutions are revolutionizing restaurant operations and customer service.",
      plan: [
        "Streamline reservation and wait time management",
        "Automate responses to common customer queries",
        "Provide personalized dining suggestions",
        "Optimize your ordering process"
      ],
      imageSrc: "https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/pexels-mizunokozuki-13432284.jpg",
      imageAlt: "Busy restaurant with overwhelmed staff"
    },
    tourism: {
      hero: "As a tourism professional, you aim to create unforgettable experiences for travelers.",
      problem: "But providing personalized service at scale and managing the complexities of global tourism can be daunting.",
      empathy: "We understand the challenges of catering to diverse needs and keeping up with ever-changing travel information.",
      guide: "AI technology is transforming the tourism industry, making personalization and efficiency possible.",
      plan: [
        "Offer personalized travel recommendations",
        "Provide real-time updates on attractions and events",
        "Implement multi-lingual support",
        "Streamline booking and itinerary management"
      ],
      imageSrc: "https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/pexels-ketut-subiyanto-4907174.jpg",
      imageAlt: "Tourist looking confused while planning a trip"
    },
    authors: {
      hero: "As an author or creative, you dream of captivating audiences with your stories and ideas.",
      problem: "Yet, engaging readers beyond the page and building a loyal fan base in the digital age can be challenging.",
      empathy: "We recognize the difficulty of standing out in a crowded market and connecting deeply with your audience.",
      guide: "AI is opening new possibilities for author-reader interactions and immersive storytelling.",
      plan: [
        "Create interactive storytelling experiences",
        "Engage with fans through automated systems",
        "Offer personalized reading recommendations",
        "Host virtual events and Q&A sessions"
      ],
      imageSrc: "https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/pexels-divinetechygirl-1181571.jpg",
      imageAlt: "Author staring at blank page, surrounded by crumpled papers"
    },
    fitness: {
      hero: "As a fitness professional or personal trainer, you're dedicated to helping clients achieve their health and wellness goals.",
      problem: "But scaling your business, providing personalized attention to each client, and staying on top of the latest fitness trends can be exhausting.",
      empathy: "We understand the challenge of balancing one-on-one coaching with the need to grow your business and reach more clients.",
      guide: "AI technology is revolutionizing the fitness industry, allowing trainers to provide personalized experiences at scale.",
      plan: [
        "Offer AI-powered personalized workout plans",
        "Implement automated progress tracking and feedback",
        "Provide 24/7 nutrition and exercise guidance",
        "Create engaging, interactive fitness challenges"
      ],
      imageSrc: "https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/pexels-thisisengineering-3912475.jpg",
      imageAlt: "Fitness trainer looking overwhelmed with client schedules and workout plans"
    },
    realestate: { // Added Real Estate content
      hero: "As a real estate agent, you aim to help clients find their dream homes.",
      problem: "But managing multiple listings, scheduling viewings, and keeping up with client inquiries can be overwhelming.",
      empathy: "We understand the challenge of balancing client needs with the demands of the market.",
      guide: "AI chatbots are transforming the real estate industry by providing 24/7 support and personalized assistance.",
      plan: [
        "Automate responses to common client inquiries",
        "Schedule and manage property viewings",
        "Provide personalized property recommendations",
        "Streamline the client onboarding process"
      ],
      imageSrc: "https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/pexels-kampus-8730024.jpg",
      imageAlt: "Real estate agent showing a house to clients"
    }
  };

  return (
    <section className="py-12 bg-gradient-to-r from-gray-900 to-gray-800 text-gray-100 rounded-3xl">
      <div className="container mx-auto px-1">
        <h2 className="text-3xl font-bold text-center mb-8">
          How Can This Help My Industry?
        </h2>
        
        <div className="flex flex-wrap justify-center mb-8">
          {industries.map((industry) => (
            <button
              key={industry.id}
              onClick={() => setSelectedIndustry(industry.id)}
              className={`px-4 py-2 m-2 rounded-full font-semibold transition-colors ${
                selectedIndustry === industry.id
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              {industry.label}
            </button>
          ))}
        </div>

        <div className="bg-gray-800 text-gray-100 max-w-4xl mx-auto rounded-lg shadow-lg overflow-hidden">
          <div className="p-6">
            <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
              <div className="md:w-2/3">
                <p className="text-lg mb-4 font-semibold text-purple-300">{content[selectedIndustry].hero}</p>
                <p className="text-lg mb-4 font-medium">{content[selectedIndustry].problem}</p>
                <p className="text-lg mb-4 text-gray-300">{content[selectedIndustry].empathy}</p>
                <p className="text-lg mb-4 font-medium text-purple-300">{content[selectedIndustry].guide}</p>
                <h4 className="text-xl font-semibold mb-2 text-gray-100">How AI Can Help:</h4>
                <ul className="list-disc list-inside mb-4 text-gray-300">
                  {content[selectedIndustry].plan.map((step, index) => (
                    <li key={index} className="mb-2">{step}</li>
                  ))}
                </ul>
              </div>
              <div className="md:w-1/3">
                <img
                  src={content[selectedIndustry].imageSrc}
                  alt={content[selectedIndustry].imageAlt}
                  className="w-full rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustrySection;
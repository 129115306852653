import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { User, MessageCircle } from 'lucide-react';

const ChatbotCard = ({ chatbot, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [animatedConversations, setAnimatedConversations] = useState(0);

  const generateMonthData = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    
    return Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      const dateString = `${currentMonth + 1}/${day}`;
      return {
        name: dateString,
        conversations: Math.floor(Math.random() * 50) + 50,
      };
    });
  };

  const data = generateMonthData();

  useEffect(() => {
    if (isHovered) {
      const conversationInterval = setInterval(() => {
        setAnimatedConversations(prev => Math.min(prev + 50, 1234));
      }, 50);
      return () => clearInterval(conversationInterval);
    } else {
      setAnimatedConversations(0);
    }
  }, [isHovered]);

  return (
    <div 
      className="bg-gray-800 text-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer w-full max-w-sm mx-auto mb-6"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center mb-4">
        <div className="relative">
          <div className={`w-16 h-16 rounded-full bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] flex items-center justify-center ${isHovered ? 'animate-pulse' : ''}`}>
            {chatbot.avatar ? (
              <img src={chatbot.avatar} alt={chatbot.image} className="w-14 h-14 rounded-full object-cover" />
            ) : (
              <User size={32} className="text-white" />
            )}
          </div>
          <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-gray-800"></div>
        </div>
        <div className="ml-4">
          <h3 className="text-xl font-bold">{chatbot.name}</h3>
          <p className="text-sm text-gray-400">{chatbot.role || 'AI Assistant'}</p>
        </div>
      </div>

      <p className="text-sm text-gray-300 mb-4">{chatbot.description}</p>

      {/* <div className="flex items-center mb-4">
        <MessageCircle size={20} className="text-[#00c4cc] mr-2" />
        <span>{animatedConversations.toLocaleString()} conversations</span>
      </div> */}

    </div>
  );
};

export default ChatbotCard;

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import IndustrySection from '../molecules/IndustrySection';
import FeaturesSection from '../molecules/FeatureSection';

const LandingPage = () => {
  const navigate = useNavigate();

  // State for industry tabs
  const [selectedIndustry, setSelectedIndustry] = React.useState('ecommerce');
  const [isExpanded, setIsExpanded] = useState(false);


  return (
    <div className="bg-gray-900 text-white min-h-screen transition-colors duration-500">
      {/* Header Section */}
        <header className="bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white">
          <div className="container mx-auto px-6 py-16 flex flex-col md:flex-row items-center text-left">
            <div className="md:w-1/2">
              <h1 className="text-3xl md:text-4xl font-extrabold mb-4">
              Provide Personalized Support to Your Customers 24/7
              </h1>
              <p className="text-md md:text-lg mb-8">
                Deliver exceptional, personalized service effortlessly—no technical skills required.
              </p>
                       <div className="flex flex-col items-left space-y-4">
                  <button
                    onClick={() => navigate('/dashboard')}
                    className="w-48 bg-gray-900 text-white font-semibold px-8 py-4 rounded-full 
                    hover:bg-gray-800 dark:bg-gray-800 dark:hover:bg-gray-700 
                    transition duration-300"
                  >
                    Free Trial
                  </button>
                  
                  <button
                    onClick={() => navigate('/contact')}
                    className="w-48 bg-white text-gray-800 font-semibold px-8 py-4 rounded-full 
                    border border-gray-300 hover:bg-gray-50 dark:bg-gray-800 
                    dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 
                    transition duration-300"
                  >
                    Contact Sales
                  </button>
                </div>
          
            </div>
            <div className="md:w-1/2">
              <img 
                src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/ta.png?t=2024-10-11T00%3A30%3A01.342Z" 
                alt="Hero Image" 
                className="mx-auto md:ml-auto rounded-2xl shadow-lg"
              />
            </div>
          </div>
        </header>

      {/* Main Content */}
      <main className="mx-auto px-1 py-12">
        {/* Section 1: We Understand Your Challenges */}
        <section className="text-center mb-12" style={{ minHeight: '400px' }}>
          <h2 className="text-3xl font-semibold mb-6 p-1">
            Are You Struggling to Meet Your Customers' Needs?
          </h2>
          <p className="text-lg md:w-3/4 mx-auto p-1">
            In today's fast-paced world, customers expect instant, personalized responses.
            Providing this level of service can be challenging without the right tools, leading to
            lost sales, frustrated customers, and overworked staff.
          </p>
            <img
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/ta%20(2).png"
            alt="Challenges"
            className="mx-auto mt-8 w-full md:w-1/2 rounded-2xl shadow-lg"
          />
        </section>

          {/* Section 2.5: Dream people dream*/}
          <section className="text-center mb-12 bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white rounded-2xl mx-0 px-0 py-6" style={{ minHeight: '400px' }}>
          <h2 className="text-3xl font-semibold mb-6 px-2">
            Imagine Retaining More Customers With Instant, Personalized Engagement
          </h2>
          <p className="text-lg md:w-3/4 mx-auto p-2">
          Now, imagine if every interaction felt personal and immediate, without you or your team lifting a finger. SmarterChatbots.com is designed to tailor responses to each customer’s unique needs, ensuring they feel valued and connected to your brand—helping you retain more customers while reducing the workload on your team.
          </p>
            <img
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/ta%20(3).png"
            alt="Challenges"
            className="mx-auto mt-8 w-full md:w-1/2 rounded-2xl shadow-lg"
          />
        </section>

        {/* Prizes Section */}
        <section className="mb-12 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg">
            <div className="max-w-3xl mx-auto pt-6">
                        <h2 className="text-3xl font-semibold text-center mb-6 text-white">🎉Win a New Mac Desktop Mini!</h2>
              <p className="text-lg text-center max-w-3xl mx-auto text-white mb-6">
                  We're thrilled to invite you to our exciting competition, where you can unleash your creativity and potentially win the new Mac Desktop Mini! We've built Smarter Chatbots to make generative AI assistants accessible to everyone, and now we want to see what amazing assistants you can create. The winner who has the most creative and impactful assistant will win the new Mac Desktop Mini.
              </p>
                <img 
                    src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Purple%20and%20Red%20Did%20You%20Know%20Fact%20LinkedIn%20Post.png" 
                    alt="Contest Promotion" 
                    className="w-full rounded-lg shadow-lg"
                />
                <button 
                    className='button p-6 py-2  my-6 rounded-full bg-gray-900 text-white font-bold shadow-lg hover:from-blue-700 hover:to-purple-800 transition-all duration-300 flex items-center justify-center w-full'
                    onClick={() => {
                        navigate('/contest');
                        window.scrollTo(0, 0);
                    }}>
                    👉  View Contest Details
                </button>
            </div>
        </section>

                   {/* Section 5: Tailored Solutions */}
       <IndustrySection></IndustrySection>

        {/* Section 2: Introducing SmarterChatbots.com */}
        <section className="text-center mb-12 pb-6  bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white rounded-2xl mx-0 px-0" style={{ minHeight: '400px' }}>
          <h2 className="text-3xl font-semibold mb-6 pt-6 px-2">
            Meet SmarterChatbots.com
          </h2>
          <p className="text-lg md:w-3/4 mx-auto px-2">
            We empower businesses to create advanced AI chatbots without any coding knowledge. Train your chatbot using your own content, customize it to match your brand's unique feel and voice, and deploy it anywhere you interact with customers. Then sit back and let the AI not only answer common questions 24/7 but also engage deeper—learning from customer conversations and providing you with valuable suggestions on areas of improvement.
          </p>
               <button 
              className='p-6 py-2 my-6 rounded-full transition-all duration-300 items-center justify-center w-1/2 bg-gray-900'
              onClick={()=>{navigate('/desktop')}}>
              👉 Try Today!
          </button>
          <img 
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/hero_image.svg" 
            alt="Hero Image" 
            className="mx-auto mt-2 w-full md:w-1/2 rounded-2xl"
          />
         
        </section>
  
        {/* Section 2: Features */}
        <FeaturesSection></FeaturesSection>

        {/* Section 3: three easy steps */}
        <section className="text-center mb-12 bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white rounded-2xl mx-0 px-2" style={{ minHeight: '400px' }}>
            <h2 className="text-3xl font-semibold mb-6 p-6">
              Three Easy Steps to Get Started
            </h2>
                  {/* Initial */}
                  <div className="p-4 space-y-4 bg-gray-800 dark:bg-gray-900 text-gray-200 dark:text-gray-100 rounded-lg shadow transition-colors duration-500 my-6">
                <h3 className="font-semibold m-2">Before Starting: Sign Up for Free Trial</h3>
                <p>Start creating your chatbot today by signing up for our free trial—no credit card required. This offers you basic features to try it out.</p>
                <button
                  onClick={() => navigate('/dashboard')}
                  className="bg-gradient-to-r from-[#00c4cc] to-[#7c2ae8] text-white font-semibold text-sm py-2 px-4 rounded-full shadow hover:from-[#00c4cc] hover:to-[#7c2ae8] transition duration-300"
                >
                  Get Started
                </button>
              </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-6">
        
          
              {/* Step 1 */}
              <div className="p-4 bg-gray-800 dark:bg-gray-900 text-gray-200 dark:text-gray-100 rounded-lg shadow transition-colors duration-500">
                <h3 className="font-semibold mb-2">Step 1: Train Your Chatbot</h3>
                <p className='mb-4'>Upload your business content—documents, website info, FAQs—to make the chatbot an expert in your business. Then customize its responses to match your brand's voice.</p>
                <img 
                  src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/AddKnowledgeSources.png" 
                  alt="Train Your Chatbot" 
                  className="w-full object-contain rounded-lg mb-4"
                  style={{ maxHeight: '350px' }}
                />
              </div>
          
              {/* Step 2 */}
              <div className="p-4 bg-gray-800 dark:bg-gray-900 text-gray-200 dark:text-gray-100 rounded-lg shadow transition-colors duration-500">
                <h3 className="font-semibold mb-2">Step 2: Customize Your Chatbot Appearance</h3>
                <p className='mb-4'>Customize the chatbot's appearance to perfectly match your brand's style.</p>
                <img 
                  src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Screenshot%202024-10-15%20at%2010.36.03%20AM.png" 
                  alt="Customize Your Chatbot Appearance" 
                  className="w-full object-cover rounded-lg mb-4"
                  style={{ maxHeight: '350px' }}
                />
              </div>
          
              {/* Step 3 */}
              <div className="p-4 bg-gray-800 dark:bg-gray-900 text-gray-200 dark:text-gray-100 rounded-lg shadow transition-colors duration-500">
                <h3 className="font-semibold mb-2">Step 3: Deploy Anywhere</h3>
                <p className='mb-4'>Deploy your chatbot where your customers are—on your website, via QR codes, REST API, code snippets, or SMS—and start engaging instantly.</p>
                <img 
                  src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/Integrations.png" 
                  alt="Deploy Your Chatbot" 
                  className="w-full object-cover rounded-lg mb-4"
                  style={{ maxHeight: '400px' }}
                />
              </div>
            </div>
          </section>





        <section className="bg-gray-900 text-white py-12">
          <h2 className="text-3xl font-bold text-center mb-8 px-2">
            Use Interactions To Learn From Your Customers
          </h2>
          <p className="text-gray-400 mb-4 text-center px-2">
            Gain valuable insights into your chatbot's performance. Our intuitive analytics dashboard helps you understand customer interactions and optimize engagement strategies.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 p-1">
            {/* Card 1 */}
            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden p-2">
              <div className="text-center mb-4">
                <h3 className="text-xl font-semibold mb-4">Conversation Insights</h3>
                <p className="text-sm text-gray-400 mb-4">
                  Analyze customer interactions to identify common questions and topics. Refine your chatbot's responses for better customer satisfaction.
                </p>
              </div>
              <img 
                src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Screenshot%202024-10-15%20at%2010.56.13%20AM.png" 
                alt="Conversation Insights" 
                className="w-full h-80 object-contain"
              />
            </div>
            {/* Card 2 */}
            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden p-4">
              <div className="text-center mb-4">
                <h3 className="font-semibold text-lg mb-2">Performance Recommendations</h3>
                <p className="text-sm">
                  Receive AI-driven suggestions to enhance your chatbot's effectiveness and customer engagement.
                </p>
              </div>
              <img 
                src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Screenshot%202024-10-15%20at%2011.00.16%20AM.png" 
                alt="Performance Recommendations" 
                className="w-full h-80 object-contain"
              />
            </div>
            {/* Card 3 */}
            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden p-4">
              <div className="text-center mb-4">
                <h3 className="font-semibold text-lg mb-2">Engagement Trends</h3>
                <p className="text-sm">
                  Track customer engagement over time to identify trends and adjust your strategies accordingly.
                </p>
              </div>
              <img 
                src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Screenshot%202024-10-15%20at%2011.03.03%20AM.png" 
                alt="Engagement Trends" 
                className="w-full h-80 object-contain"
              />
            </div>
            {/* Card 4 */}
            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden p-4">
              <div className="text-center mb-4">
                <h3 className="font-semibold text-lg mb-2">Human Handoffs</h3>
                <p className="text-sm">
                  Monitor how often conversations are escalated to human agents and optimize chatbot responses to reduce these instances.
                </p>
              </div>
              <img 
                src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/Landing%20Page%20Assets/Screenshot%202024-10-15%20at%2011.06.12%20AM.png" 
                alt="Human Handoffs" 
                className="w-full h-80 object-contain"
              />
            </div>
          </div>
        </section>

        <section className="text-center mb-12 bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white rounded-2xl mx-0 px-6" style={{ minHeight: '400px' }}>
          <h3 className="text-2xl font-semibold mb-6 p-2">
            Have Ideas For New Features? Join Us On Discord!
          </h3>
          <div className="flex justify-center items-center bg-gray-800 dark:bg-gray-900 text-gray-200 dark:text-gray-100 rounded-lg shadow transition-colors duration-500 my-6" style={{ width: '200px', height: '200px', margin: 'auto'}}>
            <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROUST4RKt42EfYGatQecAsirMI837zfKSbpQ&s"
                  alt="Discord Logo"
              className="w-24 h-24 cursor-pointer rounded-md"
            />
          </div>
        </section>




        {/* Section 3: Harness the Power of Generative AI */}
        <section
      className="mb-12 bg-gray-900 text-white rounded-2xl mx-auto px-6 py-12"
      style={{ minHeight: '500px' }}
    >
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-start">
        <div className="md:w-1/2 md:pr-8">
          <h3 className="text-2xl font-bold mb-8 text-center md:text-left">
            Under the Hood: How We Leverage Generative AI
          </h3>
          <p className="text-lg leading-relaxed mb-6">
            Most large language models (LLMs) are built for general-purpose chatbots, which aren't
            trained on your specific business information. We understand that businesses need AI
            solutions deeply integrated with their unique content and brand identity. After developing numerous LLM chatbots 
            for various businesses, we noticed a recurring processes that were time-consuming and repetitive.
          </p>

          {/* Conditionally render the additional content */}
          {isExpanded && (
            <>
              <p className="text-lg leading-relaxed mb-6">
                A significant amount of time was spent on redundant, tedious tasks like
                building Retrieval-Augmented Generation (RAG) infrastructure, setting up the chatbot
                framework, and configuring deployment options. This technical overhead distracts from
                what truly matters—training the chatbot with your business data.
              </p>
              <p className="text-lg leading-relaxed mb-6">
                While platforms like ChatGPT and upcoming models like Gemini offer integration
                through their own apps or playgrounds, they often keep the chatbot confined within
                their ecosystem. Business owners want their branded chatbot to be where their
                customers already are—on their websites, mobile apps, and preferred communication
                channels—not limited to another platform's environment.
              </p>
              <p className="text-lg leading-relaxed">
                That's why we've built a user-friendly dashboard that allows you to design and
                integrate your chatbot anywhere you need it. Customize it to match your brand's look
                and feel, train it with your specific content, and deploy it across multiple
                platforms with ease. Our solution acts as an adapter to these advanced models,
                giving you next-level customization so you can make the chatbot truly your own and
                meet your customers where they are.
              </p>
            </>
          )}

          {/* Toggle Button */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="mt-6 text-blue-500 hover:underline focus:outline-none"
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <img
            src="https://eexoqhyrxkhxjvgdbycw.supabase.co/storage/v1/object/public/avatar_images/ta%20(4).png"
            alt="AI Under the Hood"
            className="mx-auto max-w-full h-auto"
          />
        </div>
      </div>
    </section>

        {/* Section 7: The Cost of Inaction */}
        {/* <section className="text-center mb-12" style={{ minHeight: '400px' }}>
          <h2 className="text-3xl font-semibold mb-6">
            Don't Let Opportunities Slip Away
          </h2>
          <p className="text-lg md:w-3/4 mx-auto">
            Without personalized customer engagement, you risk losing customers to competitors,
            overburdening your staff, and missing out on potential sales and growth.
          </p>
          <img
            src="https://example.com/images/inaction.svg"
            alt="Cost of Inaction"
            className="mx-auto mt-8 w-full md:w-1/2"
          />
        </section> */}

        {/* Section 8: Start Your Free Trial */}
        <section className="py-6 text-center mb-12 bg-gradient-to-r from-blue-500 to-purple-600 dark:from-blue-700 dark:to-purple-800 text-white rounded-2xl mx-0 px-6" style={{ minHeight: '400px' }}>
          <h2 className="text-3xl font-semibold mb-6">
            Don't miss out, start your free trial today!
          </h2>
          <p className="text-lg md:w-3/4 mx-auto mb-8">
            Get started now and see how easy it is to transform your customer experience and boost
            your business. 
          </p>
           <button
            onClick={() => navigate('/dashboard')}
            className="bg-gray-900 text-white font-semibold px-8 py-4 rounded-full shadow hover:from-blue-600 hover:to-purple-700 dark:hover:from-blue-800 dark:hover:to-purple-900 transition duration-300"
          >
            Start Free Trial Now
          </button>
          <div className="flex justify-center mt-4 space-x-4">
            <span className="text-sm text-white-600 dark:text-gray-300">No Credit Card Required</span>
            <span className="text-sm text-white-600 dark:text-gray-300">Set Up in Under 15 Minutes</span>
            <span className="text-sm text-white-600 dark:text-gray-300">Money Back Guarantee</span>          </div>
        </section>

        {/* Section 9: FAQ */}
        <section className="mb-12" style={{ minHeight: '400px' }}>
          <h2 className="text-3xl font-semibold text-center mb-6">
            Frequently Asked Questions
          </h2>
          {/* FAQ Items */}
          <div className="max-w-3xl mx-auto space-y-6">
            {/* FAQ Item */}
            <div>
              <h3 className="font-semibold mb-2">
                Do I need technical skills to use SmarterChatbots.com?
              </h3>
              <p>
                No, our platform is designed for ease of use. You can enhance customer engagement
                without any coding or technical expertise.
              </p>
            </div>
            {/* FAQ Item */}
            <div>
              <h3 className="font-semibold mb-2">
                How does SmarterChatbots.com use generative AI?
              </h3>
              <p>
                We utilize advanced AI models like ChatGPT and Gemini to train on your specific
                content, ensuring that the chatbot interactions are accurate and reflect your brand's
                voice.
              </p>
            </div>
            {/* FAQ Item */}
            <div>
              <h3 className="font-semibold mb-2">
                Is my data secure when using AI technologies?
              </h3>
              <p>
                Absolutely. We prioritize your data security with advanced encryption and strict
                privacy protocols.
              </p>
            </div>
            {/* FAQ Item */}
            <div>
              <h3 className="font-semibold mb-2">
                What happens after the free trial?
              </h3>
              <p>
                You can choose a plan that fits your needs, with the flexibility to upgrade or cancel
                at any time.
              </p>
            </div>
            {/* Add more FAQs as needed */}
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white">
        <div className="container mx-auto px-6 py-8">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6">
              <h4 className="font-semibold mb-2">SmarterChatbots.com</h4>
              <p>© 2024 SmarterChatbots.com. All Rights Reserved.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6">
              <h4 className="font-semibold mb-2">Contact Us</h4>
              <p>Email: <a href="mailto:support@smarterchatbots.com" className="hover:underline">zachary.jeffreys.programming@gmail.com</a></p>
              <p>Discord: <a className="hover:underline">Coming Soon...</a></p>
            </div>

          </div>
         
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './styles/tailwind.css'; // Importing Tailwind CSS

const root = createRoot(document.getElementById('root'));

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const audience = "smarterchatbots-api"

root.render(
<Auth0Provider
    domain={domain}
    clientId={clientId}
    issuer={`https://${domain}/`}
    authorizationParams={{
      redirect_uri: redirectUri,
      audience: audience
      // scope: 'read:posts',
    }}
  >
    <App />
  </Auth0Provider>
);
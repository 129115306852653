import React from 'react';
import Appearance from './Appearance';
import Personality from './Personality';

const Branding = ({ chatbotData, setChatbotData, fetchChatbotData }) => {
    return (
    <div>
     <Appearance chatbotData={chatbotData} setChatbotData={setChatbotData} fetchChatbotData={fetchChatbotData}></Appearance>
    </div>
  );
};

export default Branding;
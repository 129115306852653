// chatbot-creator/src/components/organisms/Chatbot/Chatbot.js

import { useLocation } from 'react-router-dom';

import React, { useState, useEffect, useRef } from 'react';
// import { useLocation } from 'react-router-dom';
import ChatbotHeader from './ChatbotHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
// import { sendMessage, fetchChatbotData } from '../../../services/api';

const sendMessage = async (userInput, conversationId, chatbotId, messageHistory = []) => {
  const messageData = {
    user_input: userInput,
    conversation_id: conversationId, // Ensure this is provided
    message_history: messageHistory,   // Default to an empty array if not provided
    chatbot_id: chatbotId              // Ensure the chatbotId is passed
  };
  // console.log(messageData)
  try {
    console.log("Sending chat message from api.js");
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/public/chatbot/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageData),
    });

    if (!response.ok) {
      throw new Error(`Failed to send message: ${response.statusText}`);
    }
    const res = await response.json();
    console.log(res)
    return res
    
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; // Propagate the error for further handling
  }
};

const fetchChatbotData = async (chatbotId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}/chatbots/public/chatbot/info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chatbot_id: chatbotId }), // Pass the chatbotId to the API
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch chatbot data: ${response.statusText}`);
    }

    const data = await response.json();
    if (!data || typeof data !== 'object') {
      throw new Error('Invalid data format received from the server');
    }

    return data;
  } catch (error) {
    console.error('Error fetching chatbot data:', error);
    throw error; // Propagate the error
  }
};

const MinimizedView = ({ avatar, bot_name, onClick, themeColor }) => (
  <div
    className="flex items-center cursor-pointer overflow-hidden transition-all duration-300 ease-in-out p-2 rounded-pill font-semibold text-white"
    onClick={onClick}
    style={{ background: themeColor, borderRadius: '100px'  }}
  >
    <div className="flex">
      <img src={avatar} alt={bot_name} className="w-8 h-8 rounded-full mr-2" />
      {/* <span className="text-white font-semibold">{bot_name}</span> */}
    </div>
    <span className="text-left text-md">Can I Assist You?</span>
  </div>
);
const Chatbot = ({ chatbotId, isClosed, tempColor, tempAvatar, tempName }) => {

  const initialMessages = [];
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [chatbotData, setChatbotData] = useState(null);
  const [messages, setMessages] = useState(initialMessages);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState('en');
  const chatWindowRef = useRef(null);
  const [darkMode, setDarkMode] = useState(true);
  const [isMinimized, setIsMinimized] = useState(isClosed ?? true);
    // Add validation
    useEffect(() => {
      if (!chatbotId) {
        console.error('No chatbotId provided via props or URL parameters');
        return;
      }
      // Rest of your useEffect logic
    }, [chatbotId]);

  const toggleMinimized = () => {
    setIsMinimized(!isMinimized);
    console.log('Minimized state:', !isMinimized);
  };

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const resetConversation = () => {
    console.log('Resetting conversation...');
    console.log('Chatbot data:', chatbotData);
    const initial_message = chatbotData?.initial_message || 'Hello! How can I help you today?';
    setMessages([
      {
        role: 'assistant',
        content: initial_message,
      },
    ]);
  };

  const fetchData = async () => {
    console.log("fetching data");
    try {
      const data = await fetchChatbotData(chatbotId);
      setChatbotData(data);
      setMessages([
        {
          role: 'assistant',
          content: data.initial_message || 'Hello! Please add an initial message for me to greet your users.',
        },
      ]);
    } catch (error) {
      console.error('Error fetching chatbot data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [chatbotId]);

  const handleSend = async (message = input) => {
    if (!message || typeof message !== 'string' || message.trim() === '') return;
    console.log('Sending message:', message);

    const userMessage = {
      role: 'user',
      content: message,
    };

    setMessages((prev) => [...prev, userMessage]);
    setIsLoading(true);

    try {
      const response = await sendMessage(message, conversationId, chatbotId, messages);
      if (response && response.chat_history) {
        setMessages(response.chat_history);
        setConversationId(response.conversation_id || '');
      }
    } catch (error) {
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, something went wrong while sending your message. You must train me before I can chat with you.',
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
      setInput('');
      setIsTyping(true);
    }
  };
  
    const quickReplies = chatbotData?.quick_replies || ["How to set greeting message"];
  
    if (!chatbotData) return <div>...</div>;
  
    return (
      <div id="chatbot-container" className={`rounded overflow-hidden transition-all duration-300 relative ${isMinimized ? 'h-auto' : 'h-full'}`}>
        {isMinimized ? (
          <MinimizedView
            avatar={tempAvatar || chatbotData.avatar}
            bot_name={tempName || chatbotData.name}
            onClick={toggleMinimized}
            themeColor={tempColor || chatbotData.themeColor}
          />
        ) : (
          <div className={`flex flex-col h-full transition-all duration-300 ease-in-out`}>
            <ChatbotHeader
              avatar={tempAvatar || chatbotData.avatar}
              bot_name={tempName || chatbotData.name}
              position={chatbotData.role}
              language={language}
              setLanguage={setLanguage}
              themeColor={tempColor || chatbotData.themeColor}
              onReset={resetConversation}
              onToggleDarkMode={toggleDarkMode}
              onMinimize={toggleMinimized}
              darkMode={darkMode}
              handleSend={handleSend}
              setInput={setInput}
            />
            <div className="flex-1 overflow-y-auto" style={{ maxHeight: '350px' }}>
              <MessageList
                messages={messages}
                avatar={tempAvatar || chatbotData.avatar}
                userAvatar={chatbotData.userAvatar}
                darkMode={darkMode}
                themeColor={tempColor || chatbotData.themeColor}
                handleSend={handleSend}
                setInput={setInput}
                quickReplies={quickReplies}
              />
            </div>
            <div className="">
              <MessageInput
                input={input}
                setInput={setInput}
                handleSend={handleSend}
                disabled={isLoading}
                darkMode={darkMode}
                themeColor={tempColor || chatbotData.themeColor}
                loading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default Chatbot;

//   return (
//     <div
//       id="chatbot-container"
//       className={`rounded overflow-hidden transition-all duration-300 relative ${isMinimized ? 'h-auto' : 'h-full'}`}
//     >
//       {isMinimized ? (
//         <MinimizedView
//           avatar={chatbotData.avatar}
//           bot_name={chatbotData.name}
//           onClick={toggleMinimized}
//           themeColor={themeColor}
//         />
//       ) : (
//         <div className={`flex flex-col h-full  transition-all duration-300 ease-in-out`}>
    
      
//         <ChatbotHeader
//           avatar={chatbotData.avatar}
//           bot_name={chatbotData.name}
//           position={chatbotData.role}
//           language={language}
//           setLanguage={setLanguage}
//           themeColor={themeColor}
//           onReset={resetConversation}
//           onToggleDarkMode={toggleDarkMode}
//           onMinimize={toggleMinimized}
//           darkMode={darkMode}
//           handleSend={handleSend}
//           setInput={setInput}
//         />


//         <div className="flex-1 overflow-y-auto" style={{ maxHeight: '350px' }}>          <MessageList
//             messages={messages}
//             avatar={chatbotData.avatar}
//             userAvatar={chatbotData.userAvatar}
//             darkMode={darkMode}
//             themeColor={themeColor}
//             handleSend={handleSend}
//             setInput={setInput}
//             quickReplies={quickReplies}
//           />
//         </div>
        
//         <div className="">
//           <MessageInput
//             input={input}
//             setInput={setInput}
//             handleSend={handleSend}
//             disabled={isLoading}
//             darkMode={darkMode}
//             themeColor={themeColor}
//             loading={isLoading}
//           />
//         </div>
//       </div>
//       )}
//     </div>
//   );
// };

// export default Chatbot;